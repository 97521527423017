import {ApiConnector}           from 'modelx-jts-common';
import {ConnectorResult, Model} from '@mathquis/modelx';

export class FileDownloadConnector extends ApiConnector {
	// Model methods
	public fetch(model: Model, options?: object): Promise<ConnectorResult> {
		// @ts-ignore
		return this.request(`${model.path}`, {
			method: 'get',
			...options,
			responseType: 'blob',
		})
			.then(response => {
				// @ts-ignore
				const connectorResult = new ConnectorResult({ blob: response.data, }, response);
				return connectorResult;
			});
	}
}
