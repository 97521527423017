import { lazy } from 'react';

const NotFoundScreen = lazy(
	() => import(
		/* webpackChunkName: "notFoundScreen" */
		'./screens/NotFoundScreen')
);

export const routes = [
	{
		path: '/*',
		component: NotFoundScreen
	},
	{
		path: '/not_found',
		component: NotFoundScreen
	},
];
