// source: Common/Entity/Urn.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.Platform.Common.Entity.Urn', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Platform.Common.Entity.Urn = function(opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Platform.Common.Entity.Urn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.Platform.Common.Entity.Urn.displayName = 'proto.Platform.Common.Entity.Urn';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.Platform.Common.Entity.Urn.prototype.toObject = function(opt_includeInstance) {
		return proto.Platform.Common.Entity.Urn.toObject(opt_includeInstance, this);
	};


	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.Platform.Common.Entity.Urn} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.Platform.Common.Entity.Urn.toObject = function(includeInstance, msg) {
		var f, obj = {
			partition: jspb.Message.getFieldWithDefault(msg, 1, ""),
			service: jspb.Message.getFieldWithDefault(msg, 2, ""),
			resource: jspb.Message.getFieldWithDefault(msg, 3, ""),
			identifier: jspb.Message.getFieldWithDefault(msg, 4, "")
		};

		if (includeInstance) {
			obj.$jspbMessageInstance = msg;
		}
		return obj;
	};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Platform.Common.Entity.Urn}
 */
proto.Platform.Common.Entity.Urn.deserializeBinary = function(bytes) {
	var reader = new jspb.BinaryReader(bytes);
	var msg = new proto.Platform.Common.Entity.Urn;
	return proto.Platform.Common.Entity.Urn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Platform.Common.Entity.Urn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Platform.Common.Entity.Urn}
 */
proto.Platform.Common.Entity.Urn.deserializeBinaryFromReader = function(msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break;
		}
		var field = reader.getFieldNumber();
		switch (field) {
			case 1:
				var value = /** -- {string} */ (reader.readString());
				msg.setPartition(value);
				break;
			case 2:
				var value = /** -- {string} */ (reader.readString());
				msg.setService(value);
				break;
			case 3:
				var value = /** -- {string} */ (reader.readString());
				msg.setResource(value);
				break;
			case 4:
				var value = /** -- {string} */ (reader.readString());
				msg.setIdentifier(value);
				break;
			default:
				reader.skipField();
				break;
		}
	}
	return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Platform.Common.Entity.Urn.prototype.serializeBinary = function() {
	var writer = new jspb.BinaryWriter();
	proto.Platform.Common.Entity.Urn.serializeBinaryToWriter(this, writer);
	return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Platform.Common.Entity.Urn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Platform.Common.Entity.Urn.serializeBinaryToWriter = function(message, writer) {
	var f = undefined;
	f = message.getPartition();
	if (f.length > 0) {
		writer.writeString(
			1,
			f
		);
	}
	f = message.getService();
	if (f.length > 0) {
		writer.writeString(
			2,
			f
		);
	}
	f = message.getResource();
	if (f.length > 0) {
		writer.writeString(
			3,
			f
		);
	}
	f = message.getIdentifier();
	if (f.length > 0) {
		writer.writeString(
			4,
			f
		);
	}
};


/**
 * optional string partition = 1;
 * @return {string}
 */
proto.Platform.Common.Entity.Urn.prototype.getPartition = function() {
	return /** -- {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Platform.Common.Entity.Urn} returns this
 */
proto.Platform.Common.Entity.Urn.prototype.setPartition = function(value) {
	return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string service = 2;
 * @return {string}
 */
proto.Platform.Common.Entity.Urn.prototype.getService = function() {
	return /** -- {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Platform.Common.Entity.Urn} returns this
 */
proto.Platform.Common.Entity.Urn.prototype.setService = function(value) {
	return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string resource = 3;
 * @return {string}
 */
proto.Platform.Common.Entity.Urn.prototype.getResource = function() {
	return /** -- {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Platform.Common.Entity.Urn} returns this
 */
proto.Platform.Common.Entity.Urn.prototype.setResource = function(value) {
	return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string identifier = 4;
 * @return {string}
 */
proto.Platform.Common.Entity.Urn.prototype.getIdentifier = function() {
	return /** -- {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Platform.Common.Entity.Urn} returns this
 */
proto.Platform.Common.Entity.Urn.prototype.setIdentifier = function(value) {
	return jspb.Message.setProto3StringField(this, 4, value);
};


goog.object.extend(exports, proto.Platform.Common.Entity);
