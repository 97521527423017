import LoginRequiredComponent                  from './components/LoginRequiredComponent';
import { routes as AccountMenuRoutes }         from './modules/accounts/routes';
import { routes as AccountsRoutes }            from './modules/settings/account/routes';
import { routes as CompaniesRoutes }           from './modules/companies/routes';
import { routes as ConfigsRoutes }             from './modules/settings/configs/routes';
import { routes as DashboardsRoutes }          from './modules/dashboards/routes';
import { routes as ErrorsRoutes }              from './modules/errors/routes';
import { routes as ExpenseRoutes }             from './modules/expenses/routes';
import { routes as ExportRoutes }              from './modules/exports/routes';
import { routes as HomeRoutes }                from './modules/home/routes';
import { routes as LoginRoutes }               from './modules/login/routes';
import { routes as NotFoundRoutes }            from './modules/notFound/routes';
import { routes as ProjectsRoutes }            from './modules/projects/routes';
import { routes as SettingsCompaniesRoutes }   from './modules/settings/companies/routes';
import { routes as SettingsCurrencyRoutes }    from './modules/settings/currency/routes';
import { routes as AccountingDocumentsRoutes } from './modules/accountingDocuments/routes';

const moduleRoutes = [
	...HomeRoutes,
	...DashboardsRoutes,
	...SettingsCompaniesRoutes,
	...AccountsRoutes,
	...ExpenseRoutes,
	...ExportRoutes,
	...CompaniesRoutes,
	...AccountMenuRoutes,
	...ConfigsRoutes,
	...SettingsCurrencyRoutes,
	...ProjectsRoutes,
	...AccountingDocumentsRoutes,
];

export default []
	.concat(LoginRoutes)
	.concat(ErrorsRoutes)
	.concat([
		{
			path: '/',
			component: LoginRequiredComponent,
			routes: [
				...moduleRoutes,
				...NotFoundRoutes
			]
		}
	])
;
