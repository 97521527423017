import { lazy } from 'react';

const addExpenseScreen = lazy(
	() => import(
		/* webpackChunkName: "addExpenseScreen" */
		'./screens/AddExpenseScreen')
);

export const routes = [
	{
		path: '/projects/:projectId/expense_groups/:expenseGroupId/expenses/add',
		exact: true,
		component: addExpenseScreen,
		hideBreadcrumb: true,
	},
];
