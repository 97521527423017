import { computed }                from 'mobx';
import { AbstractMercureModel }    from 'modelx-jts-common';
import currentModelXMercureService from '../../currentModelXMercureService';
import ConfigProxy                 from '../../tools/ConfigProxy';
import { PlatformApiConnector }    from '../connectors/PlatformApiConnector';

export const lsdConnector = new PlatformApiConnector({
	baseURL: ConfigProxy.getServiceConfig('lsd', 'api_endpoint'),
});

export default class LsdApiModel extends AbstractMercureModel {
	static get connector() {
		return lsdConnector;
	}

	protected service = currentModelXMercureService;

	public get key() {
		return this.constructor.name + '-' + this.id;
	}

	public patch(attributes: object = {}, options: object = {}) {
		return this.save({...options, patchAttributes: attributes});
	}

	@computed
	public get pageUrl() {
		return this.path;
	}

	public get id(): number {
		return this.get('id');
	}

	@computed
	public get isEmpty(): boolean {
		return !this.get('@type');
	}
}
