import { observable } from 'mobx';

import { AbstractStore, ApiCollection, PagedCollection } from 'modelx-jts-common';

import ProjectModel                   from '../models/ProjectModel';
import ProjectSubCategoryModel        from '../models/ProjectSubCategoryModel';
import ProjectCategoryModel           from '../models/ProjectCategoryModel';
import ProjectPoleModel               from '../models/ProjectPoleModel';
import CompanyModel                   from '../models/CompanyModel';
import ExpenseGroupModel              from '../models/ExpenseGroupModel';
import ExpenseEventModel              from '../models/ExpenseEventModel';
import ExpensePurchaseOrderStepModel  from '../models/ExpensePurchaseOrderStepModel';
import ExpensePaymentVoucherModel     from '../models/ExpensePaymentVoucherModel';
import ExpenseHistoryModel            from '../models/ExpenseHistoryModel';
import AccountingDocumentExpenseModel from '../models/AccountingDocumentExpenseModel';
import AccountingDocumentModel        from '../models/AccountingDocumentModel';
import ExpenseModel                   from '../models/ExpenseModel';
import LsdApiModel                    from '../models/global/LsdApiModel';
import ExpenseMediaModel              from '../models/ExpenseMediaModel';
import { getModelFromUrn }            from '../tools/modelUrnTools';
import SupplierApiCollection          from '../collections/SupplierApiCollection';
import AccountingDocumentMediaModel   from '../models/AccountingDocumentMediaModel';
import UserApiCollection              from '../collections/UserApiCollection';

class AbstractSplitViewStore extends AbstractStore {

	@observable public accountingDocumentCollection = new ApiCollection(AccountingDocumentModel);
	@observable public accountingDocumentExpenseCollection = new ApiCollection(AccountingDocumentExpenseModel);
	@observable public accountingDocumentExpenseCollectionPaged = new PagedCollection(AccountingDocumentExpenseModel);
	@observable public accountingDocumentMediaCollection = new ApiCollection(AccountingDocumentMediaModel);

	@observable public company = new CompanyModel();

	@observable public currentExpense = new ExpenseModel();

	@observable public entity;

	@observable public expenseGroup = new ExpenseGroupModel();

	@observable public expenseDocumentCollection = new PagedCollection(ExpenseMediaModel);
	@observable public expenseEventCollection = new ApiCollection(ExpenseEventModel);
	@observable public expenseHistoryCollection = new PagedCollection(ExpenseHistoryModel);
	@observable public expensePaymentVoucherCollection = new ApiCollection(ExpensePaymentVoucherModel);
	@observable public expensePurchaseOrderStepCollection = new ApiCollection(ExpensePurchaseOrderStepModel);
	@observable public expenseUserCollection = new UserApiCollection();

	@observable public project = new ProjectModel();
	@observable public projectCategory = new ProjectCategoryModel();
	@observable public projectCategoryCollection = new ApiCollection(ProjectCategoryModel);
	@observable public projectCollection = new ApiCollection(ProjectModel);
	@observable public projectPole = new ProjectPoleModel();
	@observable public projectPoleCollection = new ApiCollection(ProjectPoleModel);
	@observable public projectSubCategory = new ProjectSubCategoryModel();
	@observable public projectSubCategoryCollection = new ApiCollection(ProjectSubCategoryModel);

	@observable public supplierCollection = new SupplierApiCollection();

	constructor() {
		super();

		this.expensePaymentVoucherCollection.addSort('createdAt', false);
	}

	public fetchData = async (expense: ExpenseModel) => {
		this.setCurrentExpense(expense);
		await this.currentExpense.fetch();

		await Promise.all([
			this.loadCurrentExpenseAccountingDocumentCollection(),

			this.loadCurrentExpenseCollection()
				.then(async () => {
					await this.loadCurrentExpenseUserCollection();
				}),

			this.loadCurrentExpenseOrganigramEntities(),

			this.loadCurrentExpenseSupplier(),
		]);
	}

	public resetCollectionsFiltersForSplitViewConstructor = () => {
		this.projectCollection.clearFilters();
		this.projectSubCategoryCollection.clearFilters();
		this.projectCategoryCollection.clearFilters();
		this.projectPoleCollection.clearFilters();
	}

	public currentDashboardUpdate = () => {
		this.currentExpense.fetch();
		this.expenseEventCollection.list();
		this.expenseHistoryCollection.list();
		this.expensePaymentVoucherCollection.list();
		this.expensePurchaseOrderStepCollection.list();
	}

	public setCurrentExpense = (expense: ExpenseModel) => {
		this.currentExpense = expense;
	}

	public loadCurrentExpenseCollection = async (): Promise<void> => {
		const {
			currentExpense,
			expenseDocumentCollection,
			expenseEventCollection,
			expenseHistoryCollection,
			expensePaymentVoucherCollection,
			expensePurchaseOrderStepCollection,
		} = this;

		await Promise.all([
			this.loadCurrentExpenseAccountingDocumentCollection(),

			expenseDocumentCollection
				.addSort('createdAt', false)
				.setFilter('expense', currentExpense.id)
				.list(),

			expenseEventCollection
				.addSort('createdAt', false)
				.setFilter('expense', currentExpense.id)
				.list(),

			expenseHistoryCollection
				.addSort('createdAt', false)
				.setFilter('expense', currentExpense.id)
				.setLimit(10)
				.list(),

			expensePaymentVoucherCollection
				.setFilter('expense', currentExpense.id)
				.list(),

			expensePurchaseOrderStepCollection
				.setFilter('expense', currentExpense.id)
				.list(),
		]);
	}

	public loadCurrentExpenseAccountingDocumentCollection = (): Promise<any> => {
		const {
			accountingDocumentCollection,
			accountingDocumentMediaCollection,
			accountingDocumentExpenseCollection,
			accountingDocumentExpenseCollectionPaged,
			currentExpense,
		} = this;

		accountingDocumentCollection.setFilter('accountingDocumentExpenses.expense', currentExpense.id);
		accountingDocumentCollection.setFilter('groups', ['public_listing']);
		accountingDocumentCollection.list();

		accountingDocumentMediaCollection.setFilter(
			'accountingDocument.accountingDocumentExpenses.expense',
			currentExpense.id
		);
		accountingDocumentMediaCollection.list();

		accountingDocumentExpenseCollection.setFilter('expense', currentExpense.id);
		accountingDocumentExpenseCollection.addSort('createdAt', false);
		accountingDocumentExpenseCollection.list();

		accountingDocumentExpenseCollectionPaged.setFilter('expense', currentExpense.id);
		accountingDocumentExpenseCollectionPaged.addSort('createdAt', false);
		accountingDocumentExpenseCollectionPaged.setPage(1);
		return accountingDocumentExpenseCollectionPaged.list();
	}

	public loadCurrentExpenseOrganigramEntities = async (): Promise<void> => {
		await this.project
			.set('id', this.currentExpense.projectId)
			.fetch();

		await Promise.all([
			this.expenseGroup
				.set('id', this.project.expenseGroupId)
				.fetch(),

			this.projectSubCategory
				.set('id', this.project.projectSubCategoryId)
				.fetch(),
		]);

		await this.projectCategory
			.set('id', this.projectSubCategory.projectCategoryId)
			.fetch();

		await this.projectPole
			.set('id', this.projectCategory.projectPoleId)
			.fetch();

		await this.company
			.set('id', this.projectPole.companyId)
			.fetch();
		// appStore.currentCompanyId = this.projectPole.companyId;
		// await this.company.fetch().then(() => appStore.setCurrentCompanyId(this.company.id));
	}

	public loadCurrentExpenseSupplier = (): Promise<any> => {
		const {currentExpense} = this;

		const supplier = getModelFromUrn(currentExpense.get('supplier.@urn'));

		if (supplier) {
			return supplier
				.fetch()
				.then(() => {
					// @ts-ignore
					this.entity = getModelFromUrn(supplier.get('entityUrn')) || new LsdApiModel();

					if (this.entity.id) {
						return this.entity.fetch();
					}
				});
		} else {
			this.entity.clear();
		}
	}

	public loadCurrentExpenseUserCollection = async (): Promise<void> => {
		const usersIds = [
			...this.currentExpense.currentResponsiblesIds,
		];

		this.expenseHistoryCollection.forEach((expenseHistory: ExpenseHistoryModel) => {
			usersIds.push(
				expenseHistory.get('createdBy.id'),
				expenseHistory.get('responsible.id'),
				expenseHistory.get('applicant.id'),
			);
		});

		await this.expenseUserCollection
			.setFilter('id', usersIds)
			.list();
	}

	public findProjectPoleForExpenseFromCollections = (currentProject: ProjectModel) => {
		let currentProjectPole = null;
		if (!!currentProject) {
			const currentProjectSubCategory = this.projectSubCategoryCollection.find(
				projectSubCategory => projectSubCategory.get('@id') === currentProject.get('projectSubCategory.@id')
			);
			if (!!currentProjectSubCategory) {
				const currentProjectCategory = this.projectCategoryCollection.find(
					projectCategory => projectCategory.get('@id') === currentProjectSubCategory.get('projectCategory.@id')
				);
				if (!!currentProjectCategory) {
					currentProjectPole = this.projectPoleCollection.find(
						projectPole => projectPole.get('@id') === currentProjectCategory.get('projectPole.@id')
					);
				}
			}
		}

		return currentProjectPole;
	}
}

export default AbstractSplitViewStore;
