import { lazy } from 'react';

const ProjectPoleDashboardScreen = lazy(
	() => import(
		/* webpackChunkName: "projectPoleDashboardScreen" */
		'./screens/ProjectPoleDashboardScreen')
);

const ProjectPoleModifyScreen = lazy(
	() => import(
		/* webpackChunkName: "projectPoleModifyScreen" */
		'./screens/ProjectPoleModifyScreen')
);

const ProjectCategoryDashboardScreen = lazy(
	() => import(
		/* webpackChunkName: "projectCategoryDashboardScreen" */
		'./screens/ProjectCategoryDashboardScreen')
);

const ProjectCategoryModifyScreen = lazy(
	() => import(
		/* webpackChunkName: "projectCategoryModifyScreen" */
		'./screens/ProjectCategoryModifyScreen')
);

const ProjectSubCategoryDashboardScreen = lazy(
	() => import(
		/* webpackChunkName: "projectSubCategoryDashboardScreen" */
		'./screens/ProjectSubCategoryDashboardScreen')
);

const ProjectSubCategoryModifyScreen = lazy(
	() => import(
		/* webpackChunkName: "projectSubCategoryModifyScreen" */
		'./screens/ProjectSubCategoryModifyScreen')
);

const ProjectDashboardScreen = lazy(
	() => import(
		/* webpackChunkName: "projectDashboardScreen" */
		'./screens/ProjectDashboardScreen')
);

const ProjectModifyScreen = lazy(
	() => import(
		/* webpackChunkName: "projectModifyScreen" */
		'./screens/ProjectModifyScreen')
);

export const routes = [
	{
		path: '/companies/:companyId/expense_groups/:expenseGroupId/project_poles/:projectPoleId',
		exact: true,
		component: ProjectPoleDashboardScreen,
		hideBreadcrumb: true
	},
	{
		path: '/project_poles/:projectPoleId/modify',
		exact: true,
		component: ProjectPoleModifyScreen,
		hideBreadcrumb: false
	},
	{
		path: '/companies/:companyId/expense_groups/:expenseGroupId/project_categories/:projectCategoryId',
		exact: true,
		component: ProjectCategoryDashboardScreen,
		hideBreadcrumb: true
	},
	{
		path: '/project_categories/:projectCategoryId/modify',
		exact: true,
		component: ProjectCategoryModifyScreen,
		hideBreadcrumb: false
	},
	{
		path: '/companies/:companyId/expense_groups/:expenseGroupId/project_sub_categories/:projectSubCategoryId',
		exact: true,
		component: ProjectSubCategoryDashboardScreen,
		hideBreadcrumb: true
	},
	{
		path: '/project_sub_categories/:projectSubCategoryId/modify',
		exact: true,
		component: ProjectSubCategoryModifyScreen,
		hideBreadcrumb: false
	},
	{
		path: '/companies/:companyId/expense_groups/:expenseGroupId/projects/:projectId',
		exact: true,
		component: ProjectDashboardScreen,
		hideBreadcrumb: true
	},
	{
		path: '/projects/:projectId/modify',
		exact: true,
		component: ProjectModifyScreen,
		hideBreadcrumb: false
	},
];
