import React              from 'react';
import Row                from 'antd/lib/row';
import Col                from 'antd/lib/col';
import Input              from 'antd/lib/input';
import Select             from 'antd/lib/select';
import FormComp           from 'antd/lib/form';
import AccountModel       from '../../../models/fondationsModels/AccountModel';
import Genders            from '../../../constants/Genders';
import i18n               from '../../../components/i18n';
import Trans              from '../../../components/Trans';

import {JtsForm, IJtsFormProps} from 'react-jts-common';

export interface IModifyAccountInfoFormProps extends IJtsFormProps {
	model: AccountModel;
}

class ModifyAccountInfoForm extends React.Component<IModifyAccountInfoFormProps> {

	public async componentDidMount() {
		const {form, model} = this.props;

		const values = {
			gender: model.get('gender'),
			firstName: model.get('firstName'),
			lastName: model.get('lastName'),
			defaultEntertainment: model.get('defaultEntertainment') || undefined
		};

		form.setFieldsValue(values);
	}

	public render() {
		const {form, model} = this.props;
		const FormCompItem = FormComp.Item;
		const {getFieldDecorator} = form;
		const Option = Select.Option;

		return <JtsForm
			{...this.props}
			notificationSuccessMessage={<Trans id="Les informations du compte ont bien été modifiées."/>}
			notificationErrorMessage={<Trans id="Les informations du compte n'ont pas été modifiées."/>}
			onSubmit={params => model.patch(params)}
			mapParams={values => ({
				gender: values.gender,
				firstName: values.firstName,
				lastName: values.lastName,
				defaultEntertainment: values.defaultEntertainment
			})}
		>
			<Row gutter={20} type="flex" align="top">

				{/* GENDER */}
				<Col xs={24} md={12}>
					<FormCompItem
						label={<Trans id="Genre"/>}>
						{getFieldDecorator('gender', {
							rules: [{
								required: true,
								message: <Trans id="Veuillez sélectionner un genre"/>
							}]
						})(
							<Select placeholder={i18n._('Choisir un genre')}
									size="large">
								{
									Genders.map((gender, idx) => {
										return <Option key={idx} value={gender.value}>{gender.label}</Option>;
									})
								}
							</Select>
						)}
					</FormCompItem>
				</Col>

				{/* FIRST_NAME */}
				<Col xs={24} md={12}>
					<FormCompItem
						label={<Trans id="Prénom"/>}>
						{getFieldDecorator('firstName', {
							rules: [{
								required: true,
								message: <Trans id="Veuillez renseigner le prénom"/>
							}]
						})(
							<Input placeholder={i18n._('Prénom')} size="large"/>
						)}
					</FormCompItem>
				</Col>

				{/* LAST_NAME */}
				<Col xs={24} md={12}>
					<FormCompItem
						label={<Trans id="Nom"/>}>
						{getFieldDecorator('lastName', {
							rules: [{
								required: true,
								message: <Trans id="Veuillez renseigner le nom"/>
							}]
						})(
							<Input placeholder={i18n._('Nom')} size="large"/>
						)}
					</FormCompItem>
				</Col>
			</Row>
		</JtsForm>;
	}
}

export default FormComp.create<IModifyAccountInfoFormProps>()(ModifyAccountInfoForm);
