import {Catalogs, setupI18n} from '@lingui/core';
import {I18nProvider}        from 'lingui-react';
import {observable}          from 'mobx';
import {observer}            from 'mobx-react';
import React                 from 'react';

const i18n = setupI18n();

const currentLanguage = observable.box(''); // setting default language here won't work, it's too soon

@observer
export class I18nManager extends React.Component<any> {
	public render() {
		return <I18nProvider
			langid={currentLanguage.get()}
			language={this.props.language}
			// i18n={i18n}
			catalogs={this.props.catalogs}
			languageData={this.props.languageData}>
			{this.props.children}
		</I18nProvider>;
	}
}

export function loadCatalogs(catalogs: Catalogs) {
	i18n.load(catalogs);
}

export default i18n;
