import React                     from 'react';
import FondationsFileUploadModel from '../../models/global/FondationsFileUploadModel';

import { ImageUpload, IImageUploadProps } from 'react-jts-common';

export interface IJtsImageUploadProps extends Omit<IImageUploadProps, 'model' | 'id'> {
	id?: string;
	model: any;
}

export default class JtsImageUpload extends React.Component<IJtsImageUploadProps> {
	public render() {
		return <ImageUpload
			id=""
			flexLayout
			model={FondationsFileUploadModel}
			previewUriCallback={model => model.get('objectUrl')}
			{...this.props}
		/>;
	}
}
