import _get from 'lodash/get';

import i18n from '../components/i18n';

const ORDER_TO_VALIDATE = {
	reference: 'ORDER_TO_VALIDATE',
	reference_for_pie: 'ORDER_TO_VALIDATE',
	label: i18n._('Commande à valider'),
	color: 'rgb(243, 156, 18)'
};

const ORDER_VALIDATED = {
	reference: 'ORDER_VALIDATED',
	label: i18n._('Commande validée'),
	color: 'rgb(63, 176, 93)'
};

const PAYMENT_TO_VALIDATE = {
	reference: 'PAYMENT_TO_VALIDATE',
	label: i18n._('Paiement à valider'),
	color: 'rgb(155, 89, 182)'
};

const PENDING_ADDITIONAL_INFORMATION = {
	reference: 'PENDING_ADDITIONAL_INFORMATION',
	label: i18n._('En attente d\'informations complémentaires'),
	color: 'rgb(55, 119, 188)'
};

const WAITING_FOR_PAYMENT = {
	reference: 'WAITING_FOR_PAYMENT',
	label: i18n._('En attente de paiement'),
	color: 'rgb(230, 126, 34)'
};

const EXPENSE_PAID = {
	reference: 'EXPENSE_PAID',
	label: i18n._('Soldée'),
	color: 'rgb(63, 176, 93)'
};

const EXPENSE_CANCELED = {
	reference: 'EXPENSE_CANCELED',
	label: i18n._('Commande annulée'),
	color: 'rgb(144, 144, 145)'
};

const EXPENSE_REFUSED = {
	reference: 'EXPENSE_REFUSED',
	label: i18n._('Dépense refusée'),
	color: 'rgb(231, 76, 60)'
};

const LITIGATION = {
	reference: 'LITIGATION',
	label: i18n._('Litige'),
	color: 'rgb(241, 196, 15)'

};

const references = {
	EXPENSE_CANCELED,
	EXPENSE_PAID,
	EXPENSE_REFUSED,
	LITIGATION,
	ORDER_TO_VALIDATE,
	ORDER_VALIDATED,
	PAYMENT_TO_VALIDATE,
	PENDING_ADDITIONAL_INFORMATION,
	WAITING_FOR_PAYMENT
};

export const allStatus = [
	ORDER_TO_VALIDATE,
	ORDER_VALIDATED,
	EXPENSE_CANCELED,
	WAITING_FOR_PAYMENT,
	PAYMENT_TO_VALIDATE,
	EXPENSE_REFUSED,
	EXPENSE_PAID,
	LITIGATION,
	PENDING_ADDITIONAL_INFORMATION
];

export const getLabel = reference => {
	if (!!references[reference]) {
		return references[reference].label;
	} else {
		return reference;
	}
};

export const getColor = reference => {
	return _get(references, `${reference}.color`, '#ccc');
};

export const expenseStatusIsCancelable = reference => {
	return !!([
		ORDER_TO_VALIDATE,
		ORDER_VALIDATED,
		PAYMENT_TO_VALIDATE,
		PENDING_ADDITIONAL_INFORMATION
	].find(ref => ref.reference === reference));
};

export const expenseStatusIsPayable = reference => {
	return !!([
		WAITING_FOR_PAYMENT,
	]).find(ref => ref.reference === reference);
};

export const expenseStatusIsClosable = reference => {
	return !!([
		EXPENSE_CANCELED,
		EXPENSE_PAID,
		EXPENSE_REFUSED,
		ORDER_VALIDATED,
	].find(ref => ref.reference === reference));
};

export const expenseStatusIsDeletable = reference => {
	return !!([
		EXPENSE_CANCELED,
		ORDER_TO_VALIDATE,
	].find(ref => ref.reference === reference));
};

export const expenseStatusIsMovable = reference => {
	return !!([
		ORDER_TO_VALIDATE,
		PENDING_ADDITIONAL_INFORMATION
	].find(ref => ref.reference === reference));
};

export const expenseStatusIsUpdatable = reference => {
	return !!([
		ORDER_TO_VALIDATE,
		PENDING_ADDITIONAL_INFORMATION
	].find(ref => ref.reference === reference));
};

export default references;
