import LsdApiModel from './global/LsdApiModel';

let me: number;

export default class MyExpenseToProcessStatsModel extends LsdApiModel {
	static get path(): string {
		return `/users/${me}/expenses/to-process/stats`;
	}

	public setMe(value) {
		me = value;

		return this;
	}
}
