import { lazy } from 'react';

const LoginScreen = lazy(
	() => import(
		/* webpackChunkName: "LoginScreen" */
		'./screens/LoginScreen')
);

const ForgetPasswordScreen = lazy(
	() => import(
		/* webpackChunkName: "ForgetPasswordScreen" */
		'./screens/ForgetPasswordScreen')
);

const ResetPasswordScreen = lazy(
	() => import(
		/* webpackChunkName: "ResetPasswordScreen" */
		'./screens/ResetPasswordScreen')
);

const NotFoundScreen = lazy(
	() => import(
		/* webpackChunkName: "NotFoundScreen" */
		'../../modules/notFound/screens/NotFoundScreen')
);

export const routes = [
	{
		path: '/login',
		exact: true,
		component: LoginScreen
	},
	{
		path: '/forget_password',
		exact: true,
		component: ForgetPasswordScreen
	},
	{
		path: '/reset_password',
		exact: true,
		component: ResetPasswordScreen
	},
	{
		path: '/not_found_public',
		component: NotFoundScreen
	}
];
