// source: Service/Fondations/UserRights.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var Common_Entity_Urn_pb = require('../../Common/Entity/Urn_pb.js');
goog.object.extend(proto, Common_Entity_Urn_pb);
goog.exportSymbol('proto.Platform.Service.Fondations.UserRights', null, global);
goog.exportSymbol('proto.Platform.Service.Fondations.UserRights.Role', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Platform.Service.Fondations.UserRights = function(opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, proto.Platform.Service.Fondations.UserRights.repeatedFields_, null);
};
goog.inherits(proto.Platform.Service.Fondations.UserRights, jspb.Message);
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.Platform.Service.Fondations.UserRights.displayName = 'proto.Platform.Service.Fondations.UserRights';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Platform.Service.Fondations.UserRights.Role = function(opt_data) {
	jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Platform.Service.Fondations.UserRights.Role, jspb.Message);
if (goog.DEBUG && !COMPILED) {
	/**
	 * @public
	 * @override
	 */
	proto.Platform.Service.Fondations.UserRights.Role.displayName = 'proto.Platform.Service.Fondations.UserRights.Role';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Platform.Service.Fondations.UserRights.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.Platform.Service.Fondations.UserRights.prototype.toObject = function(opt_includeInstance) {
		return proto.Platform.Service.Fondations.UserRights.toObject(opt_includeInstance, this);
	};


	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.Platform.Service.Fondations.UserRights} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.Platform.Service.Fondations.UserRights.toObject = function(includeInstance, msg) {
		var f, obj = {
			urn: (f = msg.getUrn()) && Common_Entity_Urn_pb.Urn.toObject(includeInstance, f),
			groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
				Common_Entity_Urn_pb.Urn.toObject, includeInstance),
			rolesList: jspb.Message.toObjectList(msg.getRolesList(),
				proto.Platform.Service.Fondations.UserRights.Role.toObject, includeInstance)
		};

		if (includeInstance) {
			obj.$jspbMessageInstance = msg;
		}
		return obj;
	};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Platform.Service.Fondations.UserRights}
 */
proto.Platform.Service.Fondations.UserRights.deserializeBinary = function(bytes) {
	var reader = new jspb.BinaryReader(bytes);
	var msg = new proto.Platform.Service.Fondations.UserRights;
	return proto.Platform.Service.Fondations.UserRights.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Platform.Service.Fondations.UserRights} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Platform.Service.Fondations.UserRights}
 */
proto.Platform.Service.Fondations.UserRights.deserializeBinaryFromReader = function(msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break;
		}
		var field = reader.getFieldNumber();
		switch (field) {
			case 1:
				var value = new Common_Entity_Urn_pb.Urn;
				reader.readMessage(value,Common_Entity_Urn_pb.Urn.deserializeBinaryFromReader);
				msg.setUrn(value);
				break;
			case 2:
				var value = new Common_Entity_Urn_pb.Urn;
				reader.readMessage(value,Common_Entity_Urn_pb.Urn.deserializeBinaryFromReader);
				msg.addGroups(value);
				break;
			case 3:
				var value = new proto.Platform.Service.Fondations.UserRights.Role;
				reader.readMessage(value,proto.Platform.Service.Fondations.UserRights.Role.deserializeBinaryFromReader);
				msg.addRoles(value);
				break;
			default:
				reader.skipField();
				break;
		}
	}
	return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Platform.Service.Fondations.UserRights.prototype.serializeBinary = function() {
	var writer = new jspb.BinaryWriter();
	proto.Platform.Service.Fondations.UserRights.serializeBinaryToWriter(this, writer);
	return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Platform.Service.Fondations.UserRights} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Platform.Service.Fondations.UserRights.serializeBinaryToWriter = function(message, writer) {
	var f = undefined;
	f = message.getUrn();
	if (f != null) {
		writer.writeMessage(
			1,
			f,
			Common_Entity_Urn_pb.Urn.serializeBinaryToWriter
		);
	}
	f = message.getGroupsList();
	if (f.length > 0) {
		writer.writeRepeatedMessage(
			2,
			f,
			Common_Entity_Urn_pb.Urn.serializeBinaryToWriter
		);
	}
	f = message.getRolesList();
	if (f.length > 0) {
		writer.writeRepeatedMessage(
			3,
			f,
			proto.Platform.Service.Fondations.UserRights.Role.serializeBinaryToWriter
		);
	}
};





if (jspb.Message.GENERATE_TO_OBJECT) {
	/**
	 * Creates an object representation of this proto.
	 * Field names that are reserved in JavaScript and will be renamed to pb_name.
	 * Optional fields that are not set will be set to undefined.
	 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
	 * For the list of reserved names please see:
	 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
	 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
	 *     JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @return {!Object}
	 */
	proto.Platform.Service.Fondations.UserRights.Role.prototype.toObject = function(opt_includeInstance) {
		return proto.Platform.Service.Fondations.UserRights.Role.toObject(opt_includeInstance, this);
	};


	/**
	 * Static version of the {@see toObject} method.
	 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
	 *     the JSPB instance for transitional soy proto support:
	 *     http://goto/soy-param-migration
	 * @param {!proto.Platform.Service.Fondations.UserRights.Role} msg The msg instance to transform.
	 * @return {!Object}
	 * @suppress {unusedLocalVariables} f is only used for nested messages
	 */
	proto.Platform.Service.Fondations.UserRights.Role.toObject = function(includeInstance, msg) {
		var f, obj = {
			urn: (f = msg.getUrn()) && Common_Entity_Urn_pb.Urn.toObject(includeInstance, f)
		};

		if (includeInstance) {
			obj.$jspbMessageInstance = msg;
		}
		return obj;
	};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Platform.Service.Fondations.UserRights.Role}
 */
proto.Platform.Service.Fondations.UserRights.Role.deserializeBinary = function(bytes) {
	var reader = new jspb.BinaryReader(bytes);
	var msg = new proto.Platform.Service.Fondations.UserRights.Role;
	return proto.Platform.Service.Fondations.UserRights.Role.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Platform.Service.Fondations.UserRights.Role} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Platform.Service.Fondations.UserRights.Role}
 */
proto.Platform.Service.Fondations.UserRights.Role.deserializeBinaryFromReader = function(msg, reader) {
	while (reader.nextField()) {
		if (reader.isEndGroup()) {
			break;
		}
		var field = reader.getFieldNumber();
		switch (field) {
			case 1:
				var value = new Common_Entity_Urn_pb.Urn;
				reader.readMessage(value,Common_Entity_Urn_pb.Urn.deserializeBinaryFromReader);
				msg.setUrn(value);
				break;
			default:
				reader.skipField();
				break;
		}
	}
	return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Platform.Service.Fondations.UserRights.Role.prototype.serializeBinary = function() {
	var writer = new jspb.BinaryWriter();
	proto.Platform.Service.Fondations.UserRights.Role.serializeBinaryToWriter(this, writer);
	return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Platform.Service.Fondations.UserRights.Role} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Platform.Service.Fondations.UserRights.Role.serializeBinaryToWriter = function(message, writer) {
	var f = undefined;
	f = message.getUrn();
	if (f != null) {
		writer.writeMessage(
			1,
			f,
			Common_Entity_Urn_pb.Urn.serializeBinaryToWriter
		);
	}
};


/**
 * optional Platform.Common.Entity.Urn urn = 1;
 * @return {?proto.Platform.Common.Entity.Urn}
 */
proto.Platform.Service.Fondations.UserRights.Role.prototype.getUrn = function() {
	return /** --{?proto.Platform.Common.Entity.Urn} */ (
		jspb.Message.getWrapperField(this, Common_Entity_Urn_pb.Urn, 1));
};


/**
 * @param {?proto.Platform.Common.Entity.Urn|undefined} value
 * @return {!proto.Platform.Service.Fondations.UserRights.Role} returns this
 */
proto.Platform.Service.Fondations.UserRights.Role.prototype.setUrn = function(value) {
	return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Platform.Service.Fondations.UserRights.Role} returns this
 */
proto.Platform.Service.Fondations.UserRights.Role.prototype.clearUrn = function() {
	return this.setUrn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Platform.Service.Fondations.UserRights.Role.prototype.hasUrn = function() {
	return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Platform.Common.Entity.Urn urn = 1;
 * @return {?proto.Platform.Common.Entity.Urn}
 */
proto.Platform.Service.Fondations.UserRights.prototype.getUrn = function() {
	return /** --{?proto.Platform.Common.Entity.Urn} */ (
		jspb.Message.getWrapperField(this, Common_Entity_Urn_pb.Urn, 1));
};


/**
 * @param {?proto.Platform.Common.Entity.Urn|undefined} value
 * @return {!proto.Platform.Service.Fondations.UserRights} returns this
 */
proto.Platform.Service.Fondations.UserRights.prototype.setUrn = function(value) {
	return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Platform.Service.Fondations.UserRights} returns this
 */
proto.Platform.Service.Fondations.UserRights.prototype.clearUrn = function() {
	return this.setUrn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Platform.Service.Fondations.UserRights.prototype.hasUrn = function() {
	return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Platform.Common.Entity.Urn groups = 2;
 * @return {!Array<!proto.Platform.Common.Entity.Urn>}
 */
proto.Platform.Service.Fondations.UserRights.prototype.getGroupsList = function() {
	return /** --{!Array<!proto.Platform.Common.Entity.Urn>} */ (
		jspb.Message.getRepeatedWrapperField(this, Common_Entity_Urn_pb.Urn, 2));
};


/**
 * @param {!Array<!proto.Platform.Common.Entity.Urn>} value
 * @return {!proto.Platform.Service.Fondations.UserRights} returns this
 */
proto.Platform.Service.Fondations.UserRights.prototype.setGroupsList = function(value) {
	return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.Platform.Common.Entity.Urn=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Platform.Common.Entity.Urn}
 */
proto.Platform.Service.Fondations.UserRights.prototype.addGroups = function(opt_value, opt_index) {
	return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.Platform.Common.Entity.Urn, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Platform.Service.Fondations.UserRights} returns this
 */
proto.Platform.Service.Fondations.UserRights.prototype.clearGroupsList = function() {
	return this.setGroupsList([]);
};


/**
 * repeated Role roles = 3;
 * @return {!Array<!proto.Platform.Service.Fondations.UserRights.Role>}
 */
proto.Platform.Service.Fondations.UserRights.prototype.getRolesList = function() {
	return /** --{!Array<!proto.Platform.Service.Fondations.UserRights.Role>} */ (
		jspb.Message.getRepeatedWrapperField(this, proto.Platform.Service.Fondations.UserRights.Role, 3));
};


/**
 * @param {!Array<!proto.Platform.Service.Fondations.UserRights.Role>} value
 * @return {!proto.Platform.Service.Fondations.UserRights} returns this
 */
proto.Platform.Service.Fondations.UserRights.prototype.setRolesList = function(value) {
	return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Platform.Service.Fondations.UserRights.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Platform.Service.Fondations.UserRights.Role}
 */
proto.Platform.Service.Fondations.UserRights.prototype.addRoles = function(opt_value, opt_index) {
	return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Platform.Service.Fondations.UserRights.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Platform.Service.Fondations.UserRights} returns this
 */
proto.Platform.Service.Fondations.UserRights.prototype.clearRolesList = function() {
	return this.setRolesList([]);
};


goog.object.extend(exports, proto.Platform.Service.Fondations);
