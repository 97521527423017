import { Model, Connector, ConnectorResult } from '@mathquis/modelx';
import axios, { AxiosInstance }              from 'axios';
import { IConnectorCurrentSession }          from './PlatformApiConnector';

export class PlatformFileUploadConnector extends Connector {

	private client: AxiosInstance;

	private currentSession: IConnectorCurrentSession;

	public setCurrentSession(value: IConnectorCurrentSession) {
		this.currentSession = value;

		return this;
	}

	public initialize() {
		this.client = axios.create({
			...this.options,
			headers: {
				'Accept': 'application/ld+json'
			},
			responseType: 'json'
		});

		this.client.interceptors.request.use((config) => {
			let tokenCheck = Promise.resolve();

			if (!this.currentSession) {
				console.error('Property currentSession is required');
			}

			if (this.currentSession.isExpired) {
				tokenCheck = tokenCheck.then(() => this.currentSession.refresh());
			}

			return tokenCheck.then(
				() => {
					// Add the JWT token to the request
					config.headers['X-Platform-Authorization'] = 'Bearer ' + this.currentSession.token;
					return config;
				}
			);
		});
	}

	public save(model: Model, options: any = {}) {
		return this.request(model.path, {
			method: 'post',
			data: options.patchAttributes || model.untransformedAttributes,
			...options
		}).then((response: any) => {
			return new ConnectorResult(response.data, response);
		});
	}

	protected request(path: string, options: object = {}) {
		return this.client(path, options).catch((err) => {
			// if ( err instanceof UnauthorizedError ) {
			// 	console.warn('OOPS!', err.message);
			// 	return this.login('user', 'password').then((response) => {
			// 		return this.request(path, options);
			// 	});
			// }
			throw err;
		});
	}
}
