import { lazy } from 'react';

const AccountModifyInfoScreen = lazy(() => import(
		/* webpackChunkName: "accountModifyInfoScreen" */
		'./screens/AccountModifyInfoScreen')
);

const AccountModifyPhotoScreen = lazy(
	() => import(
		/* webpackChunkName: "accountModifyPhotoScreen" */
		'./screens/AccountModifyPhotoScreen')
);

const AccountModifyPasswordScreen = lazy(
	() => import(
		/* webpackChunkName: "accountModifyPasswordScreen" */
		'./screens/AccountModifyPasswordScreen')
);

const AccountModifyEmailScreen = lazy(
	() => import(
		/* webpackChunkName: "accountModifyEmailScreen" */
		'./screens/AccountModifyEmailScreen')
);

export const routes = [
	{
		path: '/accounts/:accountId/modify_info',
		exact: true,
		component: AccountModifyInfoScreen
	},
	{
		path: '/accounts/:accountId/modify_photo',
		exact: true,
		component: AccountModifyPhotoScreen
	},
	{
		path: '/accounts/:accountId/modify_password',
		exact: true,
		component: AccountModifyPasswordScreen
	},
	{
		path: '/accounts/:accountId/modify_email',
		exact: true,
		component: AccountModifyEmailScreen
	}
];
