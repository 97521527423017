import appStore                          from './AppStore';
import expenseSplitViewStore             from './ExpenseSplitViewStore';
import organigramCollectionStore         from './OrganigramCollectionStore';
import organigramEntityDashboardStore    from './OrganigramEntityDashboardStore';
import organigramStore                   from './OrganigramStore';
import paymentVoucherSplitViewStore      from './PaymentVoucherSplitViewStore';
import accountingDocumentsSplitViewStore from './AccountingDocumentsSplitViewStore';

class Store {
	public appStore = appStore;
	public expenseSplitViewStore = expenseSplitViewStore;
	public organigramCollectionStore = organigramCollectionStore;
	public organigramEntityDashboardStore = organigramEntityDashboardStore;
	public organigramStore = organigramStore;
	public paymentVoucherSplitViewStore = paymentVoucherSplitViewStore;
	public accountingDocumentsSplitViewStore = accountingDocumentsSplitViewStore;
}

export default new Store();
