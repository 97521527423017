import UrnModelDictionary         from '../constants/UrnModelDictionary';
import UrnApiEntryPointDictionary from '../constants/UrnApiEntryPointDictionary';

export const getApiEntryPointFromUrn = (urn: string): string => {
	const arr = (urn || '').split(':');

	if (arr && arr.length) {
		return UrnApiEntryPointDictionary[arr.splice(0, 2).join(':')];
	}

	return '';
};

export const getModelFromUrn = (urn: string): Model | void => {
	const arr = (urn || '').split(':');

	if (arr && arr.length) {
		const id = arr.pop();
		const modelConstructor = UrnModelDictionary[arr.join(':')];

		if (modelConstructor) {
			return new modelConstructor({id});
		}
	}
};
