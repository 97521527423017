import LsdApiModel from './LsdApiModel';
import pickBy      from 'lodash/pickBy';

export class LsdApiTranslatableModel extends LsdApiModel {
	public getTrans(key: string, defaultValue: any = null) {
		return this.get('translations.fr.' + key, defaultValue);
	}

	/**
	 * Allow to filter translations properties object
	 *
	 * @param keys
	 */
	public filterTranslationsProperties(keys: string[]): object {

		const result = {};
		const translations = this.get('translations');

		if (translations) {
			Object.keys(translations).forEach(transKey => {
				result[transKey] = pickBy(translations[transKey], (value: string, key: string) => {
					return value && keys.includes(key);
				});
			});
		}

		return result;
	}
}


