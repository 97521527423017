import LsdApiModel  from './global/LsdApiModel';
import { computed } from 'mobx';

export default class ProjectPoleModel extends LsdApiModel {
	static get path(): string {
		return '/project_poles/{?id}';
	}

	@computed
	get activatedExpenseGroups() {
		return this.get('activatedExpenseGroups', []);
	}

	@computed
	get code() {
		return this.get('code');
	}

	@computed
	get color() {
		return this.get('color');
	}

	@computed
	get companyId() {
		return this.get('company.id');
	}

	@computed
	get responsibles() {
		return this.get('responsibles', []);
	}

	@computed
	get responsiblesIds() {
		return this.responsibles.map(data => data.id);
	}

	@computed
	get title() {
		return this.get('title');
	}
}
