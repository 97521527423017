import React    from 'react';
import FormComp from 'antd/lib/form';

import i18n                        from '../../../components/i18n';
import AccountModel                from '../../../models/fondationsModels/AccountModel';
import Trans                       from '../../../components/Trans';
import {passwordStrengthValidator} from '../../utils/passwordStrengthValidator';

import {IJtsFormProps, JtsForm, ConfirmInput} from 'react-jts-common';

export interface IModifyAccountEmailFormProps extends IJtsFormProps {
	model: AccountModel;
}

class ModifyAccountEmailForm extends React.Component<IModifyAccountEmailFormProps> {

	public render() {
		const {form, model} = this.props;
		const {getFieldDecorator} = form;

		return <JtsForm
			{...this.props}
			onSubmit={params => model.patch(params)}
			mapParams={values => ({email: values.email, plainPassword: values.plainPassword})}
			notificationSuccessMessage={<Trans id="L'adresse email a bien été modifiée."/>}
			notificationErrorMessage={<Trans id="L'adresse email n'a pas été modifiée."/>}
		>
			{getFieldDecorator('email', {
				rules: [
					{required: true, message: i18n._('Veuillez renseigner une adresse email')},
					{type: 'email', message: i18n._('Le format de l\'adresse email n\'est pas valide.')}
				]
			})(<ConfirmInput
				formParent={form}
				labels={[<Trans id="Nouvelle adresse email"/>, <Trans id="Confirmer l'adresse email"/>]}
				placeholders={[i18n._('Adresse email'), i18n._('Confirmer l\' adresse email')]}
				type="email"
				size="large"
			/>)}

			{getFieldDecorator('plainPassword', {
				rules: [
					{required: true, message: i18n._('Veuillez renseigner un mot de passe')},
					{validator: passwordStrengthValidator}
				]
			})(<ConfirmInput
				formParent={form}
				labels={[<Trans id="Nouveau mot de passe"/>, <Trans id="Confirmer mot de passe"/>]}
				placeholders={[i18n._('Mot de passe'), i18n._('Confirmer le mot de passe')]}
				type="password"
				size="large"
			/>)}
		</JtsForm>;
	}
}

export default FormComp.create<IModifyAccountEmailFormProps>()(ModifyAccountEmailForm);
