import { lazy } from 'react';

const MyTasksScreen = lazy(
	() => import(
		/* webpackChunkName: "myTasksScreen" */
		'./screens/MyTasksScreen')
);

const MyVouchersScreen = lazy(
	() => import(
		/* webpackChunkName: "myVouchersScreen" */
		'./screens/MyVouchersScreen')
);

const CompanyStateScreen = lazy(
	() => import(
		/* webpackChunkName: "companyStateScreen" */
		'./screens/CompanyStateScreen')
);

const ExpenseGroupsContainer = lazy(
	() => import(
		/* webpackChunkName: "expenseGroupsContainer" */
		'../expenseGroups/containers/ExpenseGroupsContainer/ExpenseGroupsContainer')
);

export const routes = [
	{
		path: '/companies/:companyId/my-tasks/',
		exact: true,
		component: MyTasksScreen,
		hideBreadcrumb: true
	},
	{
		path: '/companies/:companyId/my-vouchers/',
		exact: true,
		component: MyVouchersScreen,
		hideBreadcrumb: true
	},
	{
		path: '/companies/:companyId/state/',
		exact: true,
		component: CompanyStateScreen,
		hideBreadcrumb: false
	},
	{
		path: '/companies/:companyId/expense_groups/:expenseGroupId',
		exact: false,
		component: ExpenseGroupsContainer,
		hideBreadcrumb: true
	},
];
