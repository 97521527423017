const locationTools = {
	getValue: (uri, parameter) => {
		let res = null;
		const parts = uri.split('?');
		if (parts.length > 1) {
			const subParts = parts[1].split('&');
			subParts.forEach(subPart => {
				const [key, value] = subPart.split('=');
				if (key === parameter) {
					res = value;
				}
			});
		}
		return res;
	},
	changeValue: (uri, key, value) => {
		const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
		const separator = uri.indexOf('?') !== -1 ? '&' : '?';
		if (uri.match(re)) {
			return uri.replace(re, '$1' + key + '=' + value + '$2');
		} else {
			return uri + separator + key + '=' + value;
		}
	},
	changeValues: (uri, keysAndValues) => {
		keysAndValues.forEach(kAV => {
			uri = locationTools.changeValue(uri, kAV.key, kAV.value);
		});
		return uri;
	},
	removeKey: (uri, parameter) => {
		return locationTools.removeKeyAbstract(uri, key => key !== parameter);
	},
	removeKeys: (uri, parameters) => {
		return locationTools.removeKeyAbstract(uri, key => {
			return !parameters.find(parameter => parameter === key);
		});
	},
	removeKeyAbstract: (uri, callback) => {
		const parts = uri.split('?');
		let newUri = parts[0];
		if (parts.length > 1) {
			newUri += '?';
			let subParts = parts[1].split('&');
			subParts = subParts.filter(sP => {
				const [key] = sP.split('=');
				if (callback(key)) {
					return true;
				}
				return false;
			});
			subParts.forEach((subPart, index) => {
				const [key, value] = subPart.split('=');
				if (callback(key)) {
					newUri += key + '=' + value;
					if ((index + 1) !== subParts.length) {
						newUri += '&';
					}
				}
			});
		}
		return newUri;
	}
};

export default locationTools;
