import LsdApiModel  from './global/LsdApiModel';
import { computed } from 'mobx';
import { getLabel } from '../constants/ExpenseStatus';

export default class ExpenseModel extends LsdApiModel {
	static get path(): string {
		return '/expenses/{?id}';
	}

	@computed
	public get amountInCompanyCurrency() {
		return this.get('amountInCompanyCurrency');
	}

	@computed
	public get currentResponsibles() {
		return this.get('currentResponsibles', []);
	}

	@computed
	public get currentResponsiblesIds() {
		return this.currentResponsibles.map(data => data.id);
	}

	@computed
	public get closed() {
		return this.get('closed', false);
	}

	@computed
	public get createdById() {
		return this.get('createdBy.id');
	}

	@computed
	get previousYearAmount() {
		return this.get('previousYearAmount', 0);
	}

	@computed
	get previousYearAmountInCompanyCurrency() {
		return this.get('previousYearAmountInCompanyCurrency', 0);
	}

	@computed
	get previousYearExpenseId() {
		return this.get('previousYearExpense.id');
	}

	@computed
	get previousYearExpenseIri() {
		return this.get('previousYearExpense.@id');
	}

	@computed
	get projectId() {
		return this.get('project.id');
	}

	@computed
	get projectIri() {
		return this.get('project.@id');
	}

	@computed
	get priority() {
		return this.get('priority', false);
	}

	@computed
	get status() {
		return this.get('status');
	}

	@computed
	get statusLabel() {
		return getLabel(this.status);
	}

	@computed
	get title() {
		return this.get('title');
	}
}
