import { i18nMark } from 'lingui-react';

export const MALE = {
	label: i18nMark('Homme'),
	value: 1
};
export const FEMALE = {
	label: i18nMark('Femme'),
	value: 2
};

export default [
	MALE,
	FEMALE,
];