const ROLE_LSD_EMPLOYEE = 'ROLE_LSD_EMPLOYEE';
const ROLE_LSD_PAYMENT_MANAGER = 'ROLE_LSD_PAYMENT_MANAGER';
const ROLE_LSD_BUDGET_MANAGER = 'ROLE_LSD_BUDGET_MANAGER';
const ROLE_LSD_OPERATING_DIRECTION = 'ROLE_LSD_OPERATING_DIRECTION';
const ROLE_LSD_ADMINISTRATIVE_MANAGER = 'ROLE_LSD_ADMINISTRATIVE_MANAGER';
const ROLE_LSD_GENERAL_DIRECTION = 'ROLE_LSD_GENERAL_DIRECTION';

const ROLE_LSD_USER = 'ROLE_LSD_USER';
const ROLE_LSD_ADMIN = 'ROLE_LSD_ADMIN';
const ROLE_LSD_SUPER_ADMIN = 'ROLE_LSD_SUPER_ADMIN';

export {
	ROLE_LSD_EMPLOYEE,
	ROLE_LSD_PAYMENT_MANAGER,
	ROLE_LSD_BUDGET_MANAGER,
	ROLE_LSD_OPERATING_DIRECTION,
	ROLE_LSD_ADMINISTRATIVE_MANAGER,
	ROLE_LSD_GENERAL_DIRECTION,
	ROLE_LSD_USER,
	ROLE_LSD_ADMIN,
	ROLE_LSD_SUPER_ADMIN,
};
