import FondationsApiModel from '../global/FondationsApiModel';
import {computed}         from 'mobx';

export default class AccountModel extends FondationsApiModel {
	static get path(): string {
		return '/users/{?id}';
	}

	@computed
	get profilePictureUri() {
		return this.get('userMediaProfilePicture.objectUrl');
	}

	@computed
	get fullName() {
		return this.get('firstName', '') + ' ' + this.get('lastName', '');
	}

	@computed
	get fullNameFirstLetters() {
		let wbcPictureEmptyText = '';
		wbcPictureEmptyText += this.get('firstName') ? this.get('firstName')[0] : '';
		wbcPictureEmptyText += this.get('lastName') ? this.get('lastName')[0] : '';
		return wbcPictureEmptyText;
	}
}
