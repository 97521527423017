import React              from 'react';
import {Redirect}         from 'react-router';
import {inject, observer} from 'mobx-react';

interface IAppStoreAwareComponentProps {
	appStore?: AppStore;
	location?;
}

@inject('appStore')
@observer
export default class AppStoreAwareComponent extends React.Component<IAppStoreAwareComponentProps> {
	public render() {
		if (!this.passCurrentCompanyTest()) {
			return <Redirect to="/errors/404"/>;
		}

		return this.props.children;
	}

	private passCurrentCompanyTest() {
		const pathCompanyId = this.getPathCompanyId();

		if (!!pathCompanyId) {
			const {currentUserCompanyCollection} = this.props.appStore;

			if (!currentUserCompanyCollection.isLoading && currentUserCompanyCollection.isLoaded) {
				return !!currentUserCompanyCollection.find(companyModel => companyModel.id + '' === pathCompanyId + '');
			}
		}

		return true;
	}

	private getPathCompanyId() {
		const {pathname} = this.props.location;
		const reg = /\/companies\/(\d+)/;
		const res = pathname.match(reg);

		let companyId = null;

		if (!!res) {
			companyId = res[1];
			if (!!res && !!companyId) {
				return companyId;
			}
		}

		return null;
	}
}
