export const roles = {
	ROLE_LSD_EMPLOYEE: 'ROLE_LSD_EMPLOYEE',
	ROLE_LSD_PAYMENT_MANAGER: 'ROLE_LSD_PAYMENT_MANAGER',
	ROLE_LSD_BUDGET_MANAGER: 'ROLE_LSD_BUDGET_MANAGER',
	ROLE_LSD_OPERATING_DIRECTION: 'ROLE_LSD_OPERATING_DIRECTION',
	ROLE_LSD_ADMINISTRATIVE_MANAGER: 'ROLE_LSD_ADMINISTRATIVE_MANAGER',
	ROLE_LSD_GENERAL_DIRECTION: 'ROLE_LSD_GENERAL_DIRECTION',
	ROLE_LSD_USER: 'ROLE_LSD_USER',
	ROLE_LSD_ADMIN: 'ROLE_LSD_ADMIN',
	ROLE_LSD_SUPER_ADMIN: 'ROLE_LSD_SUPER_ADMIN',
};

export const hierarchy = {
	ROLE_LSD_EMPLOYEE: [],
	ROLE_LSD_PAYMENT_MANAGER : [ 'ROLE_LSD_EMPLOYEE' ],
	ROLE_LSD_BUDGET_MANAGER: [ 'ROLE_LSD_EMPLOYEE' ],
	ROLE_LSD_OPERATING_DIRECTION: [ 'ROLE_LSD_BUDGET_MANAGER' ],
	ROLE_LSD_ADMINISTRATIVE_MANAGER: [ 'ROLE_LSD_BUDGET_MANAGER' ],
	ROLE_LSD_GENERAL_DIRECTION: [ 'ROLE_LSD_OPERATING_DIRECTION', 'ROLE_LSD_ADMINISTRATIVE_MANAGER' ],
	ROLE_LSD_USER: [],
	ROLE_LSD_ADMIN: [ 'ROLE_LSD_USER', 'ROLE_LSD_GENERAL_DIRECTION', 'ROLE_LSD_PAYMENT_MANAGER' ],
	ROLE_LSD_SUPER_ADMIN: [ 'ROLE_LSD_ADMIN' ],
};

const getRolesAtMost = role => {
	let rolesArray = [];
	rolesArray.push(role);
	if (!!hierarchy[role]) {
		hierarchy[role].forEach(subRole => {
			rolesArray = rolesArray.concat(getRolesGranted(subRole));
		});
	}
	return rolesArray;
};

const getRolesAtLeast = role => {
	let rolesArray = [];
	rolesArray.push(role);
	for (const [key, value] of Object.entries(hierarchy)) {
		if (value.indexOf(role) >= 0) {
			rolesArray = rolesArray.concat(getRolesAtLeast(key));
		}
	}
	return rolesArray;
};

const getRolesGranted = (role, atLeast = true) => {
	if (atLeast) {
		return getRolesAtLeast(role);
	}

	return getRolesAtMost(role);
};

export const isRoleGranted = (role, targetRole) => {
	if (role === targetRole) {
		return true;
	} else {
		return !!hierarchy[role].find(r => {
			return isRoleGranted(r, targetRole);
		});
	}
};

export default getRolesGranted;
