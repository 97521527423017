import BudgetModel  from './BudgetModel';
import { computed } from 'mobx';

export default class PoleBudgetModel extends BudgetModel {
	static get path(): string {
		return '/project_poles/_budget';
	}

	@computed
	get activatedExpenseGroups() {
		return this.get('activatedExpenseGroups', []);
	}

	@computed
	get code() {
		return this.get('code');
	}

	@computed
	get color() {
		return this.get('color');
	}

	@computed
	get responsibles() {
		return this.get('responsibles', []);
	}

	@computed
	public get responsiblesIds() {
		return this.responsibles.map(data => data.id);
	}

	@computed
	get title() {
		return this.get('title');
	}
}
