import { lazy } from 'react';

const homeScreen = lazy(
	() => import(
		/* webpackChunkName: "homeScreen" */
		'./screens/HomeScreen')
);

export const routes = [
	{
		path     : '/',
		exact    : true,
		component: homeScreen
	}
];
