import React                from 'react';
import {Trans as MainTrans} from 'lingui-react';
import i18n                 from './i18n';

export default class Trans extends React.Component<{ id: string }> {
	public render() {
		const {id} = this.props;

		return <MainTrans id={i18n._(id)}/>;
	}
}
