import { lazy } from 'react';

const globalConfigurationScreen = lazy(
	() => import(
		/* webpackChunkName: "settingsGlobalConfigurationScreen" */
		'./screens/GlobalConfigurationScreen')
);

export const routes = [
	{
		path: '/settings/configs',
		component: globalConfigurationScreen
	}
];
