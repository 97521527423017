import {observable} from 'mobx';

import {ApiCollection, SplitViewCollection} from 'modelx-jts-common';

import AbstractSplitViewStore         from './AbstractSplitViewStore';
import AccountingDocumentExpenseModel from '../models/AccountingDocumentExpenseModel';
import AccountingDocumentModel        from '../models/AccountingDocumentModel';
import ExpenseModel                   from '../models/ExpenseModel';
import ExpensePaymentVoucherIdModel   from '../models/ExpensePaymentVoucherIdModel';
import ExpensePaymentVoucherModel     from '../models/ExpensePaymentVoucherModel';
import UserApiCollection              from '../collections/UserApiCollection';

class PaymentVoucherSplitViewStore extends AbstractSplitViewStore {

	@observable
	public expenseCollection = new ApiCollection(ExpenseModel);

	@observable
	public paymentVoucherCollection = new SplitViewCollection(ExpensePaymentVoucherModel, ExpensePaymentVoucherIdModel);

	@observable
	public splitViewAccountingDocumentCollection = new ApiCollection(AccountingDocumentModel);

	@observable
	public splitViewAccountingDocumentExpenseCollection = new ApiCollection(AccountingDocumentExpenseModel);

	@observable
	public userCollection = new UserApiCollection();

}

export default new PaymentVoucherSplitViewStore();
