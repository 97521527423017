import { Model, Collection, Connector, ConnectorResult, ConnectorResults } from '@mathquis/modelx';
import axios, { AxiosInstance }                                            from 'axios';

export interface IConnectorCurrentSession {
	isExpired: boolean;
	token: string;
	locale: string;
	refresh: () => Promise<any>;
	logout: () => void;
}

export class PlatformApiConnector extends Connector {

	protected client: AxiosInstance;
	protected currentSession: IConnectorCurrentSession;

	public setCurrentSession(value: IConnectorCurrentSession) {
		this.currentSession = value;

		return this;
	}

	public initialize() {
		this.client = axios.create({
			...this.options,
			headers: {
				...this.options.headers,
				'Accept': 'application/ld+json',
				'all-locales': 1
			},
			responseType: 'json'
		});

		this.client.interceptors.request.use((config) => {
			let tokenCheck = Promise.resolve();

			if (!this.currentSession) {
				console.error('Property currentSession is required');
			}

			if (this.currentSession.isExpired) {
				tokenCheck = tokenCheck.then(() => this.currentSession.refresh());
			}

			return tokenCheck.then(
				() => {
					// Add the JWT token to the request
					config.headers['X-Platform-Authorization'] = 'Bearer ' + this.currentSession.token;
					return config;
				}
			);
		});
	}

	// Collection methods
	public list(collection: Collection, options: {
		limit?: number,
		offset?: number,
		filters?: any;
		sorts?: object
	} = {}) {
		const params: {
			pagination: boolean;
			itemsPerPage: number;
			page: number;
		} = {...options.filters};

		params.pagination = !!(options.limit || options.offset);
		if (params.pagination) {
			if (options.limit) {
				params.itemsPerPage = Math.max(1, options.limit);
			}
			if (options.offset) {
				params.page = Math.floor(options.offset / Math.max(1, params.itemsPerPage)) + 1;
			}
		}

		if (options.sorts) {
			Object.keys(options.sorts).forEach(key => {
				params['order[' + key + ']'] = options.sorts[key] ? 'ASC' : 'DESC';
			});
		}

		return this.request(collection.path, {
			...options,
			method: 'get',
			params
		}).then((response: any) => {
			return new ConnectorResults(response.data['hydra:member'], response);
		});
	}

	// Model methods
	public fetch(model: Model, options: object = {}) {
		return this.request(model.path, {
			method: 'get',
			...options
		}).then((response: any) => {
			return new ConnectorResult(response.data, response);
		});
	}

	public save(model: Model, options: any = {}) {
		return this.request(model.path, {
			method: model.id ? 'put' : 'post',
			data: options.patchAttributes || model.untransformedAttributes,
			...options
		}).then((response: any) => {
			return new ConnectorResult(response.data, response);
		});
	}

	public destroy(model: Model, options: object = {}) {
		return this.request(model.path, {
			method: 'delete',
			...options
		}).then((response) => {
			return new ConnectorResult(model.attributes, response);
		});
	}

	protected request(path: string, options: any = {}) {
		return this.client(path.replace(/\/$/, ''), {
			...options,
			headers: {
				'locale': this.currentSession.locale,
				...options.headers
			}
		}).catch((err) => {
			this.onRequestError(err);
		});
	}

	protected onRequestError(err) {
		if (err.response.status === 401) {
			return this.currentSession.logout();
		}

		throw err;
	}
}
