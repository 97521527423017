import { lazy } from 'react';

const ExpenseDashboardScreen = lazy(
	() => import(
		/* webpackChunkName: "expenseDashboardScreen" */
		'./screens/ExpenseDashboardScreen')
);

const ExpenseMoveScreen = lazy(
	() => import(
		/* webpackChunkName: "expenseMoveScreen" */
		'./screens/ExpenseMoveScreen')
);

const AddExpensePaymentVoucherScreen = lazy(
	() => import(
		/* webpackChunkName: "addExpensePaymentVoucherScreen" */
		'./screens/AddExpensePaymentVoucherScreen')
);

const AddExpenseAccountingDocumentScreen = lazy(
	() => import(
		/* webpackChunkName: "addExpenseAccountingDocumentScreen" */
		'./screens/AddExpenseAccountingDocumentScreen')
);

export const routes = [
	{
		path: '/expenses/:expenseId/add_expense_payment_voucher',
		exact: true,
		component: AddExpensePaymentVoucherScreen,
		hideBreadcrumb: true,
	},
	{
		path: '/expenses/:expenseId/add_expense_accounting_document',
		exact: true,
		component: AddExpenseAccountingDocumentScreen,
		hideBreadcrumb: true,
	},
	{
		path: '/expenses/:expenseId',
		exact: true,
		component: ExpenseDashboardScreen,
		hideBreadcrumb: true
	},
	{
		path: '/expenses/:expenseId/move',
		exact: true,
		component: ExpenseMoveScreen,
		hideBreadcrumb: true
	}
];
