import { lazy } from 'react';

const CompanySplitViewScreen = lazy(
	() => import(
		/* webpackChunkName: "companySplitViewScreen" */
		'./screens/CompanySplitViewScreen')
);

const CompanyDashboardScreen = lazy(
	() => import(
		/* webpackChunkName: "companyDashboardScreen" */
		'./screens/CompanyDashboardScreen')
);

const CompanyModifyInfoScreen = lazy(
	() => import(
		/* webpackChunkName: "companyModifyInfoScreen" */
		'./screens/CompanyModifyInfoScreen')
);

const CompanyAddInfoScreen = lazy(
	() => import(
		/* webpackChunkName: "companyAddInfoScreen" */
		'./screens/CompanyAddInfoScreen')
);

const userGroupCompanyModifyScreen = lazy(
	() => import(
		/* webpackChunkName: "userGroupCompanyModifyScreen" */
		'./screens/UserGroupCompanyModifyScreen')
);

export const routes = [{
	path: '/settings/companies',
	exact: true,
	component: CompanySplitViewScreen,
	hideBreadcrumb: true
}, {
	path: '/settings/companies/add',
	exact: true,
	component: CompanyAddInfoScreen
}, {
	path: '/settings/companies/:companyId',
	exact: true,
	component: CompanyDashboardScreen
}, {
	path: '/settings/companies/:companyId/modify_info',
	exact: true,
	component: CompanyModifyInfoScreen
}, {
	path: '/settings/companies/:companyId/user_group_companies/modify',
	exact: true,
	component: userGroupCompanyModifyScreen
}];
