import { Model, ConnectorResult }      from '@mathquis/modelx';
import ConfigProxy                     from '../../tools/ConfigProxy';
import { PlatformFileUploadConnector } from '../connectors/PlatformFileUploadConnector';

export const fileUploadConnector = new PlatformFileUploadConnector({
	baseURL: ConfigProxy.getServiceConfig('fondations', 'api_endpoint'),
});

export default class FondationsFileUploadModel extends Model {
	static get path(): string {
		return '/files/upload';
	}

	static get connector() {
		return fileUploadConnector as any;
	}

	public save(options: object = {}) {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			formData.set('file', this.get('attachment'));
			return this.connector.save(this, {...options, patchAttributes: formData}).then(
				(result: ConnectorResult) => {
					this.onSaveSuccess(result, options);
					resolve(this);
				}
			).catch((err) => reject(err));
		});

	}
}
