import {inject, observer} from 'mobx-react';

import React from 'react';

export interface IMenuExpenseNoticeProps {
	appStore?;
	companyId: number;
	expenseGroupId?: number;
}

@inject('appStore') @observer
export default class MenuExpenseNotice extends React.Component<IMenuExpenseNoticeProps> {
	public render() {
		const {appStore, companyId, expenseGroupId} = this.props;

		const path = expenseGroupId ? `${companyId}.expenseGroups.${expenseGroupId}` : `${companyId}.total`;
		const total = appStore.expenseToProcessStats.get(path);

		if (total) {
			return <div style={styles.container} className="menu-expense-notice">
				{appStore.expenseToProcessStats.get(path)}
			</div>;
		}

		return null;
	}
}

const styles = {
	container: {
		backgroundColor: '#E67E22',
		fontSize: 11,
		lineHeight: 1,
		padding: '2px 4px',
		color: '#fff',
		borderRadius: '50%',
		zIndex: 1
	}
};

