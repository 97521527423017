import { lazy } from 'react';

const CurrencyListScreen = lazy(
	() => import(
		/* webpackChunkName: "currencyListScreen" */
		'./screens/CurrencyListScreen')
);

export const routes = [{
	path: '/settings/currency',
	exact: true,
	component: CurrencyListScreen
}];
