import { ApiCollection } from 'modelx-jts-common';
import EntityModel       from '../models/fondationsModels/EntityModel';
import SupplierModel     from '../models/SupplierModel';
// import {ENTITY_CREATED} from '../constants/EntityStates';

const MAX_IDS = 200;

export default class SupplierApiCollection extends ApiCollection {

	public entityCollection = new ApiCollection(EntityModel);

	constructor() {
		super(SupplierModel);

		// this.entityCollection = new ApiCollection(EntityModel);
		// this.entityCollection.setFilter('state', ENTITY_CREATED.value);
	}

	public list(options?: object): Promise<Collection> {
		return new Promise((resolve, reject) => {
			super.list(options)
				.then(() => {
					resolve(this);

					let entitiesIds = this.map((supplier: SupplierModel) => supplier.entityId);
					entitiesIds = entitiesIds.filter((id, index, array) => array.indexOf(id) === index);

					let a = 0;
					let b = MAX_IDS;

					while (a < entitiesIds.length) {
						const ids = entitiesIds.slice(a, b);

						const collEntity = new ApiCollection(EntityModel);
						collEntity.setFilter('id', ids).list().then(() => {
							collEntity.forEach(entity => {
								this.entityCollection.add(entity);

								const supplier = this.find((s: SupplierModel) => s.entityUrn === entity.get('@urn'));

								if (supplier instanceof SupplierModel) {
									supplier.setSupplier(entity);
								}
							});
						});

						a = b;
						b = b + MAX_IDS;
					}

					// this.entityCollection.setFilter('id', entitiesIds);
					// this.entityCollection
					// 	.list()
					// 	.then(() => {
					// 		const entitiesModels = this.entityCollection.models.slice();
					//
					// 		this.forEach((supplier: SupplierModel) => {
					// 			supplier.isLoaded = false;
					//
					// 			const entityIndex = entitiesModels.findIndex(entity => {
					// 				return entity.get('@urn') === supplier.get('entityUrn');
					// 			});
					//
					// 			if (entityIndex > -1) {
					// 				supplier.setSupplier(entitiesModels[entityIndex]);
					// 			}
					//
					// 			supplier.isLoaded = true;
					// 		});
					// 	});
				})
				.catch(err => reject(err));
		});
	}
}
