import i18n from '../components/i18n';

export const REGISTERED = {
	label: i18n._('Enregistrée en comptabilité'),
	value: 'REGISTERED',
};

export const NOT_REGISTERED = {
	label: i18n._('Pas comptabilisée'),
	value: 'NOT_REGISTERED',
};

export const references = {REGISTERED, NOT_REGISTERED};

export const getLabel = state => {
	if (!!references[state]) {
		return references[state].label;
	}

	return NOT_REGISTERED.label;
};

export default references;
