import React          from 'react';
import ReactDOM       from 'react-dom';
import {AppContainer} from 'react-hot-loader';
import Raven          from 'raven-js';

import routes from './routes';
import App    from './components/App/App';

import ConfigProxy from './tools/ConfigProxy';

import './documentAddEventListenerKeyPress';

const render = Component => {
	ReactDOM.hydrate(
		<AppContainer>
			<Component
				routes={routes}
				history={history}
			/>
		</AppContainer>,
		document.getElementById('main')
	);
};

render(App);

if (module.hot) {
	module.hot.accept('./components/App/App', () => {
		const nextApp = require('./components/App/App').default;
		render(nextApp) ;
	});
}

if (!!ConfigProxy.get('SENTRY_DSN')) {
	Raven.config(ConfigProxy.get('SENTRY_DSN'), {
		release: version,
		environment: ConfigProxy.get('APP_ENV'),
	}).install();
}

Raven.setUserContext({});
