console.log('frontConfig.js');

if ( !window.FRONT_CONFIG ) {
	window.FRONT_CONFIG = {
		APP_ENV: 'dev',
		SENTRY_DSN: '',
	};
}

const FrontConfig = window.FRONT_CONFIG;

export default FrontConfig;
