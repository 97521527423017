import LsdApiModel from './global/LsdApiModel';

import {action, computed, observable} from 'mobx';

import {getModelFromUrn} from '../tools/modelUrnTools';

import userDefaultPicture from '../../images/user.png';

export default class AccountLsdModel extends LsdApiModel {
	static get path(): string {
		return '/users/{?id}';
	}

	@observable
	private _user: Model | void;

	@computed
	public get userProfilePictureUri() {
		if (this._user && this._user.id && this._user.get('userMediaProfilePicture.objectUrl')) {
			return this._user.get('userMediaProfilePicture.objectUrl');
		}

		return userDefaultPicture;
	}

	@computed
	public get userIsLoaded(): boolean {
		return this._user && this._user.isLoaded;
	}

	@computed
	public get userIsLoading(): boolean {
		return this._user && this._user.isLoading;
	}

	@computed
	public get userFullName() {
		if (this._user && this._user.id) {
			return this._user.get('firstName', '') + ' ' + this._user.get('lastName', '');
		}

		return '';
	}

	@computed
	public get userFirstName() {
		if (this._user && this._user.id) {
			return this._user.get('firstName', '');
		}

		return '';
	}

	@computed
	public get userEnabled(): boolean {
		return this._user && this._user.id && this._user.get('enabled');
	}

	@computed
	public get userEmail(): string {
		return this._user && this._user.id && this._user.get('email');
	}

	@computed
	public get userFullNameFirstLetters() {
		let wbcPictureEmptyText = '';

		if (this._user && this._user.id) {
			wbcPictureEmptyText += this.get('firstName') ? this.get('firstName')[0] : '';
			wbcPictureEmptyText += this.get('lastName') ? this.get('lastName')[0] : '';
		}

		return wbcPictureEmptyText;
	}

	@action
	public async userFetchAsync(): Promise<Model | void> {
		if (this._user && this._user.id && !this._user.isLoading && !this._user.isLoaded) {
			await this._user.fetch();
		}
	}

	protected transform(attributes) {
		const result = super.transform(attributes);

		if (!this._user || !this._user.id) {
			const accountLsdModel = getModelFromUrn(attributes.userUrn);

			if (accountLsdModel) {
				this.setUser(accountLsdModel);
			}
		}

		return result;
	}

	@action
	public setUser(accountLsdModel) {
		this._user = accountLsdModel;
	}
}
