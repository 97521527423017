import React from 'react';
import Trans from '../../components/Trans';

export function passwordStrengthValidator(rule, value, callback) {
	const hasCapitalLetters = str => rule && (/[A-Z]/.test(str));
	const hasNumbers = str => /\d/.test(str);

	if (!value) {
		callback();
	} else if (!hasCapitalLetters(value)) {
		callback(<Trans id="Le mot de passe doit contenir au moins une lettre majuscule"/>);
	} else if (!hasNumbers(value)) {
		callback(<Trans id="Le mot de passe doit contenir au moins un chiffre"/>);
	} else if (value.length < 8) {
		callback(<Trans id="Le mot de passe doit contenir au minimum 8 caractères"/>);
	} else if (value.length > 20) {
		callback(<Trans id="Le mot de passe ne doit pas dépasser 20 caractères"/>);
	} else {
		callback();
	}
}
