import { computed }             from 'mobx';
import { Model }                from '@mathquis/modelx';
import connectorWithCache       from '../decorators/connectorWithCache';
import ConfigProxy              from '../../tools/ConfigProxy';
import { PlatformApiConnector } from '../connectors/PlatformApiConnector';

export const connector = new (connectorWithCache(PlatformApiConnector))({
	baseURL: ConfigProxy.getServiceConfig('fondations', 'api_endpoint'),
});

export default class FondationsApiModel extends Model {
	static get connector() {
		return connector;
	}

	public get key() {
		return this.constructor.name + '-' + this.id;
	}

	public patch(attributes: object = {}, options: object = {}) {
		return this.save({...options, patchAttributes: attributes});
	}

	@computed
	public get pageUrl() {
		return this.path;
	}

	public get id(): number {
		return this.get('id');
	}

	@computed
	public get isEmpty(): boolean {
		return !this.get('@type');
	}
}
