import GroupLsdModel  from './GroupLsdModel';
import LsdApiModel    from './global/LsdApiModel';
import currentSession from '../currentSession';

export default class CompanyModel extends LsdApiModel {
	static get path(): string {
		return '/companies/{?id}';
	}

	public getGroupFromUserGroupCompanies(coll): GroupLsdModel | null {
		if (!currentSession.lsdGroups.length || !coll.length) {
			return null;
		}

		const userGroupCompany = coll
			.find(userGroup => userGroup.get('company.id') === this.id);

		return currentSession.lsdGroups
			.find(group => group.id === userGroupCompany.get('group.id')) as GroupLsdModel;
	}
}
