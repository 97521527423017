import LsdApiModel               from './global/LsdApiModel';
import { FileDownloadConnector } from './connectors/FileDownloadConnector';
import ConfigProxy               from '../tools/ConfigProxy';

export const fileDownloadConnector = new FileDownloadConnector({
	baseURL: ConfigProxy.getServiceConfig('lsd', 'api_endpoint'),
});

export default class AccountingDocumentPdfsModel extends LsdApiModel {
	static get path(): string {
		return '/accounting_document_pdfs';
	}

	static get connector() {
		return fileDownloadConnector as any;
	}
}
