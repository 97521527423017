import Session                                                     from './models/Session';
import { connector }                                               from './models/global/FondationsApiModel';
import { lsdConnector }                                            from './models/global/LsdApiModel';
import { publicConnector }                                         from './models/global/FondationsPublicApiModel';
import { csvConnector }                                            from './models/global/FondationsApiCsvModel';
import { csvLsdConnector }                                         from './models/global/LsdApiCsvModel';
import { fileUploadConnector }                                     from './models/global/FondationsFileUploadModel';
import { companyMediaUploadConnector }                             from './models/CompanyMediaUploadModel';
import { expenseMediaUploadConnector }                             from './models/ExpenseMediaUploadModel';
import { accountingDocumentMediaUploadConnector }                  from './models/AccountingDocumentMediaUploadModel';
import { expenseGroupMediaUploadConnector }                        from './models/ExpenseGroupMediaUploadModel';
import { fileDownloadConnector }                                   from './models/ExpensePdfModel';
import { fileDownloadConnector as accountingDocumentPdfConnector } from './models/AccountingDocumentPdfsModel';
import { fileDownloadConnector as accountingDocumentZipConnector } from './models/AccountingDocumentZipModel';

const session = new Session();

connector.setCurrentSession(session);
lsdConnector.setCurrentSession(session);
publicConnector.setCurrentSession(session);
csvConnector.setCurrentSession(session);
csvLsdConnector.setCurrentSession(session);
fileUploadConnector.setCurrentSession(session);
companyMediaUploadConnector.setCurrentSession(session);
expenseGroupMediaUploadConnector.setCurrentSession(session);
expenseMediaUploadConnector.setCurrentSession(session);
accountingDocumentMediaUploadConnector.setCurrentSession(session);
fileDownloadConnector.setCurrentSession(session);
accountingDocumentPdfConnector.setCurrentSession(session);
accountingDocumentZipConnector.setCurrentSession(session);

export default session;
