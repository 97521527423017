const getProperty = (obj, path, defaultValue = null) =>
	String.prototype.split.call(path, /[,[\].]+?/)
		.filter(Boolean)
		.reduce((array, key) => (Object.hasOwnProperty.call(array, key) ? array[key] : defaultValue), obj);

const inArray = (array, search, equal) => {
	// tslint:disable-next-line:prefer-for-of
	for (let i = 0; i < array.length; i++) {
		if (equal(array[i], search)) {
			return true;
		}
	}
	return false;
};

const pushIfNotExist = (array, toPush, equal) => {
	if (!inArray(array, toPush, equal)) {
		array.push(toPush);
	}
};

const removeAccents = str => {
	return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export default {
	getProperty,
	inArray,
	pushIfNotExist,
	removeAccents,
};
