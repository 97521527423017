import { i18nMark } from 'lingui-react';

const invoice = {
	label: i18nMark('Facture'),
	short: i18nMark('FAC'),
	reference: 'invoice',
};

const asset = {
	label: i18nMark('Avoir'),
	short: i18nMark('AV'),
	reference: 'asset',
};

const expenseReport = {
	label:  i18nMark('Note de frais'),
	short:  i18nMark('NdF'),
	reference: 'expenseReport'
};

const depositInvoice = {
	label:  i18nMark(`Facture d'acompte`),
	short:  i18nMark('AC'),
	reference: 'depositInvoice'
};

const references = {
	invoice,
	asset,
	expenseReport,
	depositInvoice,
};

export const getLabel = reference => {
	if (!!references[reference]) {
		return references[reference].label;
	} else {
		return reference;
	}
};

export const getLabelShort = reference => {
	if (!!references[reference]) {
		return references[reference].short;
	} else {
		return reference;
	}
};

export default references;
