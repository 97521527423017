import LsdApiModel  from './global/LsdApiModel';
import { computed } from 'mobx';
import { getLabel } from '../constants/ExpenseStatus';
import moment       from 'moment-timezone';

export default class ExpenseHistoryModel extends LsdApiModel {
	static get path(): string {
		return '/expense_histories/{?id}';
	}

	@computed
	public get accountingDocumentNumber() {
		return this.get('accountingDocument.number');
	}

	@computed
	public get applicantId() {
		return this.get('applicant.id');
	}

	@computed
	public get createdAt() {
		return this.get('createdAt');
	}

	@computed
	public get createdAtMomentDateTime() {
		return moment.utc(this.createdAt).local().format('DD/MM/YYYY, HH[h]mm');
	}

	@computed
	public get createdById() {
		return this.get('createdBy.id');
	}

	@computed
	public get paymentVoucherPosition() {
		return this.get('paymentVoucher.position');
	}

	@computed
	public get paymentVoucherStep() {
		return this.get('paymentVoucherStep');
	}

	@computed
	public get purchaseOrderStep() {
		return this.get('purchaseOrderStep');
	}

	@computed
	public get responsibleId() {
		return this.get('responsible.id');
	}

	@computed
	public get status() {
		return this.get('status');
	}

	@computed
	public get statusLabel() {
		return getLabel(this.status);
	}

	@computed
	public get type() {
		return this.get('type');
	}
}
