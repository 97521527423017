import { AbstractStore, ApiCollection, PagedCollection } from 'modelx-jts-common';
import PoleBudgetModel                                   from '../models/PoleBudgetModel';
import CategoryBudgetModel                               from '../models/CategoryBudgetModel';
import SubCategoryBudgetModel                            from '../models/SubCategoryBudgetModel';
import ProjectModel                                      from '../models/ProjectModel';
import AccountLsdModel                                   from '../models/AccountLsdModel';
import { ROLE_LSD_BUDGET_MANAGER }                       from '../constants/Roles';
import { action, computed, observable, toJS }            from 'mobx';
import locationTools                                     from '../tools/locationTools';
import appStore                                          from './AppStore';
import ProjectPoleStatsModel                             from '../models/ProjectPoleStatsModel';
import ProjectCategoryStatsModel                         from '../models/ProjectCategoryStatsModel';
import ExpenseBudgetModel                                from '../models/ExpenseBudgetModel';
import ProjectSubCategoryStatsModel                      from '../models/ProjectSubCategoryStatsModel';
import ProjectStatsModel                                 from '../models/ProjectStatsModel';
import ProjectPoleModel                                  from '../models/ProjectPoleModel';
import UserApiCollection                                 from '../collections/UserApiCollection';
import ProjectCategoryModel                              from '../models/ProjectCategoryModel';

const defaultProjectPoleColor = '#4eadaa';

export class OrganigramStore extends AbstractStore {

	public budgetManagerCollection = new ApiCollection(AccountLsdModel);
	public expenseCollection = new PagedCollection(ExpenseBudgetModel);
	public piloteCollection = new ApiCollection(AccountLsdModel);
	public projectCategoryCollection = new ApiCollection(CategoryBudgetModel);
	public projectCategoryStats = new ProjectCategoryStatsModel();
	public projectCollection = new ApiCollection(ProjectModel);
	public projectPoleCollection = new ApiCollection(PoleBudgetModel);
	public projectPoleStats = new ProjectPoleStatsModel();
	public projectStats = new ProjectStatsModel();
	public projectSubCategoryCollection = new ApiCollection(SubCategoryBudgetModel);
	public projectSubCategoryStats = new ProjectSubCategoryStatsModel();
	public userCollection =  new UserApiCollection();

	public dirty = false;

	@observable
	public state;

	@action
	public historyReplace;

	@action
	public historyRemove;

	private _expenseGroupId;

	constructor() {
		super();

		this.projectPoleCollection.addSort('position', true);
		this.projectCategoryCollection.addSort('position', true);
		this.projectSubCategoryCollection.addSort('position', true);
		this.projectCollection.addSort('title', true);
		this.expenseCollection.addSort('createdAt', false);
		this.expenseCollection.setLimit(10);

		this.historyReplace = parameter => {
			window.history.replaceState(
				null,
				null,
				locationTools.changeValues(
					window.location.href,
					parameter
				));
		};

		this.historyRemove = parameter => {
			if (typeof parameter === 'object') {
				window.history.replaceState(
					null,
					null,
					locationTools.removeKeys(
						window.location.href,
						parameter
					));
			} else {
				window.history.replaceState(
					null,
					null,
					locationTools.removeKey(
						window.location.href,
						parameter
					));
			}
		};

		this.state = {
			activeTab: locationTools.getValue(window.location.href, 'tab') || 'dashboard',

			activationMode: false,

			projectPoleSelectedColor: defaultProjectPoleColor,

			projectCategorySelected: null,
			projectPoleSelected: null,
			projectSelected: null,
			projectSubCategorySelected: null,

			responsiblesMode: false,

			recoverMode: false
		};
	}

	public setExpenseGroupId = (id: number, force = false): Promise<any> => {
		if (
			(appStore.currentCompanyId &&
				(!!id && this._expenseGroupId + '' !== id + ''))
			|| force
		) {
			this.clearStore();
			this._expenseGroupId = id;
			return this.fetchProjectPoleCollection(id);
		}
	}

	/**
	 * Deprecated because can not return a promise
	 * @deprecated
	 *
	 * @param id
	 */
	public set expenseGroupId(id: number) {
		if (!!id && this._expenseGroupId + '' !== id + '') {
			this.clearStore();
			this.fetchProjectPoleCollection(id);
			this._expenseGroupId = id;
		}
	}

	public get expenseGroupId() {
		return this._expenseGroupId;
	}

	public clearStore = () => {
		this.projectPoleCollection.clear();
		this.projectCategoryCollection.clear();
		this.projectSubCategoryCollection.clear();
		this.projectCollection.clear();
		this.expenseCollection.clear();

		this.state = Object.assign({}, this.state, {
			projectPoleSelected: null,
			projectCategorySelected: null,
			projectSubCategorySelected: null,
			projectSelected: null
		});
	}

	public fetchBudgetManagerCollection = async (): Promise<void> => {
		const companyId = appStore.currentCompanyId;
		this.budgetManagerCollection.setFilter('userGroupCompanies.company', companyId);
		this.budgetManagerCollection.setFilter('isGranted', ROLE_LSD_BUDGET_MANAGER);
		await this.budgetManagerCollection.list();
	}

	public fetchPiloteCollection = async (): Promise<void> => {
		const companyId = appStore.currentCompanyId;
		this.piloteCollection.setFilter('userGroupCompanies.company', companyId);
		await this.piloteCollection.list();
	}

	public fetchProjectPoleCollection = async (expenseGroupId = this.expenseGroupId): Promise<void> => {
		const companyId = appStore.currentCompanyId;

		await Promise.all([
			this.projectPoleStats
				.set('expenseGroup', expenseGroupId)
				.fetch(),

			this.projectPoleCollection
				.setFilter('company', companyId)
				.setFilter('budgetExpenseGroup', expenseGroupId)
				.list(),
		]);

		this.projectPoleCollection.forEach((projectPole: ProjectPoleModel) => {
			const activated = projectPole.activatedExpenseGroups.find(
				activatedExpenseGroup => {
					const js = toJS(activatedExpenseGroup);
					return js.id + '' === expenseGroupId + '';
				}
			);

			projectPole.set('activated', !!activated);
		});

		await this.fetchUsers();
	}

	public fetchProjectCategoryCollectionAndSetColor = async (
		projectPoleId,
		projectPoleColor = defaultProjectPoleColor,
		expenseGroupId = this.expenseGroupId
	): Promise<void> => {
		await this.fetchProjectCategoryCollection(projectPoleId, expenseGroupId);
		this.state.projectPoleSelectedColor = projectPoleColor;
	}

	public fetchProjectCategoryStats = async (
		projectPoleId = this.state.projectPoleSelected,
		expenseGroupId = this.expenseGroupId
	): Promise<void> => {
		this.projectCategoryStats.set({
			projectPole: projectPoleId,
			expenseGroup: expenseGroupId
		});
		await this.projectCategoryStats.fetch();
	}

	public fetchProjectSubCategoryStats = async (
		projectCategoryId = this.state.projectCategorySelected,
		expenseGroupId = this.expenseGroupId
	): Promise<void> => {
		this.projectSubCategoryStats.set({
			projectCategory: projectCategoryId,
			expenseGroup: expenseGroupId
		});
		await this.projectSubCategoryStats.fetch();
	}

	public fetchProjectCategoryCollection = async (
		projectPoleId,
		expenseGroupId = this.expenseGroupId,
	): Promise<void> => {
		if (!this.state.recoverMode) {
			this.historyReplace([
				{key: 'projectPole', value: projectPoleId}
			]);
			this.historyRemove(['projectCategory', 'projectSubCategory', 'project']);
		}

		await Promise.all([
			this.fetchProjectCategoryStats(projectPoleId, expenseGroupId),

			this.projectCategoryCollection
				.setFilter('projectPole', projectPoleId)
				.setFilter('budgetExpenseGroup', expenseGroupId)
				.list(),
		]);

		if (this.state.recoverMode) {
			this.state = Object.assign({}, this.state, {projectPoleSelected: projectPoleId});
		} else if (projectPoleId !== this.state.projectPoleSelected) {
			this.projectSubCategoryCollection.clear();
			this.projectCollection.clear();
			this.expenseCollection.clear();
			this.state = Object.assign({}, this.state, {
				projectPoleSelected: projectPoleId,
				projectCategorySelected: null,
				projectSubCategorySelected: null,
				projectSelected: null
			});
		}

		this.projectCategoryCollection.forEach((projectCategory: ProjectCategoryModel) => {
			const activated = projectCategory.activatedExpenseGroups.find(
				activatedExpenseGroup => {
					const js = toJS(activatedExpenseGroup);
					return js.id + '' === expenseGroupId + '';
				}
			);

			projectCategory.set('activated', !!activated);
		});
	}

	public fetchProjectSubCategoryCollection = async (
		projectCategoryId,
		expenseGroupId = this.expenseGroupId
	): Promise<void> => {
		if (!this.state.recoverMode) {
			this.historyReplace([
				{key: 'projectPole', value: this.state.projectPoleSelected},
				{key: 'projectCategory', value: projectCategoryId}
			]);
			this.historyRemove(['projectSubCategory', 'project']);
		}

		const promises = [];

		if (!!this.state.projectPoleSelected) {
			promises.push(
				this.fetchProjectCategoryStats(this.state.projectPoleSelected, expenseGroupId),
			);
		}

		promises.push(
			this.fetchProjectSubCategoryStats(projectCategoryId, expenseGroupId),
		);

		await Promise.all([
			...promises,

			this.projectSubCategoryCollection
				.setFilters({
					projectCategory: projectCategoryId,
					budgetExpenseGroup: expenseGroupId,
				})
				.list(),
		]);

		if (this.state.recoverMode) {
			this.state = Object.assign({}, this.state, {projectCategorySelected: projectCategoryId});
		} else if (projectCategoryId !== this.state.projectCategorySelected) {
			this.projectCollection.clear();
			this.expenseCollection.clear();

			this.state = Object.assign({}, this.state, {
				projectCategorySelected: projectCategoryId,
				projectSubCategorySelected: null,
				projectSelected: null
			});
		}

		this.projectSubCategoryCollection.forEach(projectSubCategory => {
			const activated = projectSubCategory.get('activatedExpenseGroups').find(
				activatedExpenseGroup => {
					const js = toJS(activatedExpenseGroup);
					return js.id + '' === expenseGroupId + '';
				}
			);

			projectSubCategory.set('activated', !!activated);
		});
	}

	public fetchProjectCollection = async (projectSubCategoryId, expenseGroupId = this.expenseGroupId): Promise<void> => {
		if (!this.state.recoverMode) {
			this.historyReplace([
				{key: 'projectPole', value: this.state.projectPoleSelected},
				{key: 'projectCategory', value: this.state.projectCategorySelected},
				{key: 'projectSubCategory', value: projectSubCategoryId}
			]);
			this.historyRemove(['project']);
		}

		await Promise.all([
			this.fetchProjectStats(projectSubCategoryId, expenseGroupId),

			this.projectCollection
				.setFilters({
					projectSubCategory: projectSubCategoryId,
					expenseGroup: expenseGroupId,
				})
				.list(),
		]);

		if (this.state.recoverMode) {
			this.state = Object.assign({}, this.state, {projectSubCategorySelected: projectSubCategoryId});
		} else {
			this.state = Object.assign({}, this.state, {
				projectSubCategorySelected: projectSubCategoryId,
				projectSelected: null
			});
		}
	}

	public fetchProjectStats = async (
		projectSubCategoryId = this.state.projectSubCategorySelected,
		expenseGroupId = this.expenseGroupId
	): Promise<void> => {
		this.projectStats.set({
			projectSubCategory: projectSubCategoryId,
			expenseGroup: expenseGroupId
		});
		await this.projectStats.fetch();
	}

	public fetchUsers = async (): Promise<void> => {
		this.userCollection.clear();

		const usersIds = [];

		this.projectPoleCollection.forEach((projectPole: ProjectPoleModel) => {
			usersIds.push(...projectPole.responsiblesIds);
		});

		this.expenseCollection.forEach((expense: ExpenseBudgetModel) => {
			usersIds.push(
				...expense.currentResponsiblesIds,
				expense.createdById,
			);
		});

		if (!usersIds.length) {
			return;
		}

		await this.userCollection
			.setFilter('id', usersIds)
			.list();
	}

	public fetchExpenseCollection = async (projectId): Promise<void> => {
		if (!this.state.recoverMode) {
			this.historyReplace([
				{key: 'projectPole', value: this.state.projectPoleSelected},
				{key: 'projectCategory', value: this.state.projectCategorySelected},
				{key: 'projectSubCategory', value: this.state.projectSubCategorySelected},
				{key: 'project', value: projectId}
			]);
		}

		// reset page to first page for expenses box
		this.expenseCollection.setPage(1);
		this.expenseCollection.setFilter('project', projectId);

		await this.expenseCollection.list();

		if (this.state.recoverMode) {
			this.state = Object.assign({}, this.state, {projectSelected: projectId});
		} else {
			this.state = Object.assign({}, this.state, {
				projectSelected: projectId
			});
		}
	}

	@action
	public setState = (newState) => {
		this.state = Object.assign({}, this.state, newState);
	}

	@computed
	public get currentProjectPole(): ProjectPoleModel {
		// @ts-ignore
		return this.projectPoleCollection.find(
			projectPole => projectPole.id + '' === this.state.projectPoleSelected + ''
		);
	}

	@computed
	public get currentProjectCategory() {
		return this.projectCategoryCollection.find(
			projectCategory => projectCategory.id + '' === this.state.projectCategorySelected + ''
		);
	}

	@computed
	public get currentProjectSubCategory() {
		return this.projectSubCategoryCollection.find(
			projectSubCategory => projectSubCategory.id + '' === this.state.projectSubCategorySelected + ''
		);
	}

	@computed
	public get currentProject() {
		return this.projectCollection.find(
			project => project.id + '' === this.state.projectSelected + ''
		);
	}
}

export default new OrganigramStore();
