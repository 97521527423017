import { AbstractStore, ApiCollection }       from 'modelx-jts-common';
import { action, computed, observable, toJS } from 'mobx';
import UserGroupCompanyModel                  from '../models/UserGroupCompanyModel';
import AccountLsdModel                        from '../models/AccountLsdModel';
import GroupLsdModel                          from '../models/GroupLsdModel';
import store                                  from './index';
import CompanyPaymentVoucherStepModel         from '../models/CompanyPaymentVoucherStepModel';
import CompanyPurchaseOrderStepModel          from '../models/CompanyPurchaseOrderStepModel';
import getRolesGranted                        from '../constants/RolesHierarchy';
import CurrencyModel                          from '../models/CurrencyModel';
import CompanyModel                           from '../models/CompanyModel';
import ExpenseGroupModel                      from '../models/ExpenseGroupModel';
import { jsTools }                            from '../tools';
import MyExpenseToProcessStatsModel           from '../models/MyExpenseToProcessStatsModel';
import UserApiCollection                      from '../collections/UserApiCollection';
import VatModel                               from '../models/VatModel';

export class AppStore extends AbstractStore {

	/**
	 * Collections for current user and menu
	 */
	public currentUserCompanyCollection = new ApiCollection(CompanyModel);
	public currentUserGroupCompanyCollection = new ApiCollection(UserGroupCompanyModel);

	/**
	 * Current company
	 */
	public currentCompany = new CompanyModel();

	/**
	 * Collections for current company
	 * All users, all userGroupCompanies, all groups and all steps
	 */
	public currentCompanyExpenseGroupCollection = new ApiCollection(ExpenseGroupModel);

	public currentCompanyUserCollection = new UserApiCollection();

	public currentCompanyEnabledUserCollection = new UserApiCollection();
	public currentCompanyEnabledUserGroupCompanyCollection = new ApiCollection(UserGroupCompanyModel);

	// @todo: remove and use instead groupCollection
	public currentCompanyGroupCollection = new ApiCollection(GroupLsdModel);

	public currentCompanyPaymentVoucherStepCollection = new ApiCollection(CompanyPaymentVoucherStepModel);
	public currentCompanyPurchaseOrderStepCollection = new ApiCollection(CompanyPurchaseOrderStepModel);

	public currentCompanyVatCollection = new ApiCollection(VatModel);

	/**
	 * Collections for app
	 */
	public currencyCollection = new ApiCollection(CurrencyModel);

	// Utilisé pour les compteurs de notifications dans le menu
	public expenseToProcessStats = new MyExpenseToProcessStatsModel();

	/**
	 * Ordered helpers objects
	 */
	@observable
	public orderedGroupById = {};
	@observable
	public orderedUserById = {};
	@observable
	public orderedUserByGroup = {};
	@observable
	public orderedUserGroupCompanyCollection = {};

	public ordered = false;

	public history;

	@observable
	public isLoading = false;

	/**
	 * currentUserId
	 */
	private _currentUserId = null;

	/**
	 * currentCompanyId set from this.props.match.params
	 */
	@observable
	private _currentCompanyId = null;

	/**
	 * currentExpenseGroupId set from this.props.match.params
	 */
	@observable
	private _currentExpenseGroupId = null;

	/**
	 * last loaded companyId
	 */
	private companyIdForCollections = null;

	/**
	 * last loaded companyId too
	 */
	private companyIdForStepCollections = null;

	@computed
	public get currentCompanyCurrency(): CurrencyModel {
		const currentCompanyCurrency = this.currencyCollection.find(
			currency => currency.get('@id') === this.currentCompany.get('currency.@id')
		);
		return !!currentCompanyCurrency ? currentCompanyCurrency as CurrencyModel : new CurrencyModel();
	}

	@computed
	public get orderedCurrentCompanyUserCollection() {

		const users = [...this.currentCompanyEnabledUserCollection.models];

		// sort by firstName
		users.slice().sort((a, b) => {
			if (a.get('firstName') > b.get('firstName')) {
				return 1;
			}
			if (a.get('firstName') < b.get('firstName')) {
				return -1;
			}
			return 0;
		});

		return users;

	}

	constructor() {
		super();

		this.currentCompanyEnabledUserCollection.addSort('userSync.firstName', true);
		this.currentCompanyEnabledUserCollection.addSort('userSync.lastName', true);

		this.orderedGroupById = {};
		this.orderedUserById = {};
		this.orderedUserByGroup = {};
		this.orderedUserGroupCompanyCollection = {};

		this.getCurrentUserGroup = this.getCurrentUserGroup.bind(this);
		this.getUserByUri = this.getUserByUri.bind(this);
		this.getCurrencyByUri = this.getCurrencyByUri.bind(this);
		this.getUsersGranted = this.getUsersGranted.bind(this);
	}

	public clearStore() {
		this.currentUserCompanyCollection.clear();
		this.currentUserGroupCompanyCollection.clear();
		this.currentCompany.clear();
		this.currentCompanyExpenseGroupCollection.clear();
		this.currentCompanyUserCollection.clear();
		this.currentCompanyEnabledUserCollection.clear();
		this.currentCompanyEnabledUserGroupCompanyCollection.clear();
		this.currentCompanyGroupCollection.clear();
		this.currentCompanyPaymentVoucherStepCollection.clear();
		this.currentCompanyPurchaseOrderStepCollection.clear();
		this.currentCompanyVatCollection.clear();
		this.expenseToProcessStats.clear();

		this.orderedGroupById = {};
		this.orderedUserById = {};
		this.orderedUserByGroup = {};
		this.orderedUserGroupCompanyCollection = {};

		this.ordered = false;

		this._currentUserId = null;
		this._currentCompanyId = null;
		this._currentExpenseGroupId = null;
		this.companyIdForCollections = null;
		this.companyIdForStepCollections = null;
	}

	public set currentUserId(id: number) {
		if (this._currentUserId !== id) {
			const previousCurrentUserId = this._currentUserId;
			this._currentUserId = id;
			this.loadAllCurrentUserAndMenuData(id)
				.catch(
				() => this._currentUserId = previousCurrentUserId
			);
		}
	}

	public get currentUserId() {
		return this._currentUserId;
	}

	public setCurrentCompanyId(id: number): Promise<any> {
		return this.handleSetCurrentCompanyId(id);
	}

	/**
	 * @deprecated
	 *
	 * @param id
	 */
	public set currentCompanyId(id: number) {
		this.handleSetCurrentCompanyId(id);
	}

	public get currentCompanyId() {
		return this._currentCompanyId;
	}

	@action
	public setCurrentExpenseGroupId(id: number) {
		this._currentExpenseGroupId = id;
	}

	/**
	 * @deprecated
	 *
	 * @param id
	 */
	public set currentExpenseGroupId(id: number) {
		this.setCurrentExpenseGroupId(id);
	}

	@computed
	public get currentExpenseGroupId() {
		return this._currentExpenseGroupId;
	}

	@computed
	public get currentExpenseGroup() {
		return this.currentCompanyExpenseGroupCollection.find(e => e.id + '' === this.currentExpenseGroupId + '');
	}

	public loadAllCurrentUserAndMenuData(userId = this.currentUserId): Promise<any> {
		this.currentUserCompanyCollection.setFilter('userGroupCompanies.user', userId);
		this.currentUserCompanyCollection.setFilter('enabled', true);
		this.currentUserCompanyCollection.addSort('userSync.fullName', true);

		this.currentUserGroupCompanyCollection.setFilter('user', userId);

		return Promise.all([
			this.currentUserCompanyCollection.list(),
			this.currentUserGroupCompanyCollection.list(),
		]);
	}

	public loadCurrentCompany(companyId = this.currentCompanyId): Promise<any> {
		this.currentCompany.set('id', companyId);
		return this.currentCompany.fetch();
	}

	/**
	 * By default take currentCompanyId
	 * @param companyId
	 */
	public loadCurrentCompanyData(companyId = this.currentCompanyId, force = false): Promise<any> {
		if (!force && this.companyIdForCollections + '' === companyId + '') {
			return;
		}

		this.currentCompanyExpenseGroupCollection.clear();

		const previousCompanyId = this.companyIdForCollections;
		this.companyIdForCollections = companyId;

		this.currentCompanyExpenseGroupCollection.setFilter('archived', false);
		this.currentCompanyExpenseGroupCollection.setFilter('company', companyId);
		this.currentCompanyExpenseGroupCollection.addSort('position', true);

		this.currentCompanyUserCollection.setFilter('userGroupCompanies.company', companyId);

		this.currentCompanyEnabledUserCollection.setFilter('userGroupCompanies.company', companyId);
		this.currentCompanyEnabledUserCollection.setFilter('userSync.enabled', true);

		this.currentCompanyEnabledUserGroupCompanyCollection.setFilter('company', companyId);
		this.currentCompanyEnabledUserGroupCompanyCollection.setFilter('user.userSync.enabled', true);

		this.currentCompanyVatCollection.setFilter('company', companyId);
		this.currentCompanyVatCollection.addSort('rate', false);

		const promises = [
			this.currentCompanyExpenseGroupCollection.list(),
			this.currentCompanyUserCollection.list(),
			this.currentCompanyEnabledUserCollection.list(),
			this.currentCompanyEnabledUserGroupCompanyCollection.list(),
			this.currentCompanyVatCollection.list(),
		];

		if (!this.currentCompanyGroupCollection.isLoaded && !this.currentCompanyGroupCollection.isLoading) {
			promises.push(this.currentCompanyGroupCollection.list());
		}

		if (!this.currencyCollection.isLoaded && !this.currencyCollection.isLoading) {
			promises.push(this.currencyCollection.list());
		}

		return Promise
			.all(promises)
			.then(
				() => {
					store.appStore.orderAllCollection();
					// this.currentCompanyUserCollection.forEach((user: AccountLsdModel) => user.userFetchAsync());
				}
			).catch(
				() => {
					this.companyIdForCollections = previousCompanyId;
				}
			);
	}

	@action
	public fetchStepsCollection(companyId = this.currentCompanyId) {
		if (this.companyIdForStepCollections + '' === companyId + '') {
			return;
		}

		const previousCompanyId = this.companyIdForStepCollections;
		this.companyIdForStepCollections = companyId;

		this.currentCompanyPaymentVoucherStepCollection.addSort('paymentVoucherStep.position', true);
		this.currentCompanyPaymentVoucherStepCollection.setFilters({company: companyId});

		this.currentCompanyPurchaseOrderStepCollection.addSort('purchaseOrderStep.position', true);
		this.currentCompanyPurchaseOrderStepCollection.setFilters({company: companyId});

		return Promise.all([
			this.currentCompanyPaymentVoucherStepCollection.list(),
			this.currentCompanyPurchaseOrderStepCollection.list(),
		]).catch(() => {
			this.companyIdForStepCollections = previousCompanyId;
		});
	}

	public getCurrentUserGroup(user: AccountLsdModel) {
		if (!user) {
			return null;
		}

		if (!this.ordered) {
			this.orderAllCollection();
		}

		let currentUserGroup = null;
		const currentUserGroupCompany = this.orderedUserGroupCompanyCollection[user.id];
		if (!!currentUserGroupCompany) {
			currentUserGroup = this.orderedGroupById[currentUserGroupCompany.get('group.id')];
		}

		return currentUserGroup;
	}

	@observable
	public getUserByUri(userUri): AccountLsdModel | null {
		if (typeof userUri === 'object') {
			userUri = toJS(userUri)['@id'];
		}

		if (typeof userUri !== 'string') {
			return null;
		}

		return this.currentCompanyUserCollection.find(
			(user: AccountLsdModel) => user.get('@id') === userUri
		) as AccountLsdModel | null;
	}

	public getUsersGranted(role: string, atLeast = true): AccountLsdModel[] {
		const rolesGranted = getRolesGranted(role, atLeast);
		const users = [];

		this.currentCompanyGroupCollection.forEach(group => {
			if (rolesGranted.indexOf(group.get('reference')) >= 0) {
				if (this.orderedUserByGroup[group.id]) {
					// users = users.concat(this.orderedUserByGroup[group.id]);
					this.orderedUserByGroup[group.id].forEach(user => {
						jsTools.pushIfNotExist(users, user, (a, b) => a.id === b.id);
					});
				}
			}
		});

		// // add admins
		// this.currentCompanyUserCollection.map(user => {
		// 	user.get('roles', []).forEach(userRole => {
		// 		if (!!userRole.match(/LSD/g) && !!userRole.match(/ADMIN/g)) {
		// 			jsTools.pushIfNotExist(users, user, (a, b) => a.id === b.id);
		// 		}
		// 	});
		// });

		// users.forEach((user: AccountLsdModel) => user.userFetchAsync());

		// sort by firstName
		users.slice().sort((a, b) => {
			if (a.get('firstName') > b.get('firstName')) {
				return 1;
			}
			if (a.get('firstName') < b.get('firstName')) {
				return -1;
			}
			return 0;
		});

		return users;
	}

	@observable
	public getCurrencyByUri(currencyUri) {
		return this.currencyCollection.find(currency => {
			return currency.get('@id') === currencyUri;
		});
	}

	private handleSetCurrentCompanyId(id: number): Promise<any> {
		const previousCurrentCompanyId = this._currentCompanyId;
		this._currentCompanyId = id;

		if (!!id && previousCurrentCompanyId + '' !== id.toString()) {
			this.isLoading = true;

			return Promise
				.all([
					this.loadCurrentCompany(id),
					this.loadCurrentCompanyData(id),
					this.fetchStepsCollection(id)
				])
				.catch(
					() => this._currentCompanyId = previousCurrentCompanyId
				)
				.finally(() => this.isLoading = false);
		}
	}

	@action
	private orderAllCollection() {
		this.ordered = false;
		this.orderGroupById();
		this.orderUserById();
		this.orderUserGroupCompanyCollection();
		this.ordered = true;
		return true;
	}

	@action
	private orderGroupById() {
		this.orderedGroupById = {};
		this.currentCompanyGroupCollection.forEach(group => {
			this.orderedGroupById[group.id] = group;
		});
	}

	@action
	private orderUserById() {
		this.orderedUserById = {};
		this.currentCompanyUserCollection.forEach((user: AccountLsdModel) => {
			this.orderedUserById[user.id] = user;
			// user.userFetchAsync();
		});
	}

	@action
	private orderUserGroupCompanyCollection() {
		this.orderedUserGroupCompanyCollection = {};
		this.orderedUserByGroup = {};
		this.currentCompanyEnabledUserGroupCompanyCollection.forEach(userGroupCompany => {

			const cugc = this.orderedUserGroupCompanyCollection[userGroupCompany.get('user.id')];

			if (
				!!cugc && cugc.get('group.priority', 0) < userGroupCompany.get('group.priority', 0)
				|| !cugc
			) {
				this.orderedUserGroupCompanyCollection[userGroupCompany.get('user.id')] = userGroupCompany;
			}

			// ------------ ------------ ------------

			if (!this.orderedUserByGroup[userGroupCompany.get('group.id')]) {
				this.orderedUserByGroup[userGroupCompany.get('group.id')] = [];
			}

			this.orderedUserByGroup[userGroupCompany.get('group.id')].push(
				this.orderedUserById[userGroupCompany.get('user.id')]
			);
		});
	}
}

export default new AppStore();
