import {ModelXMercureService} from 'modelx-jts-common';

import ConfigProxy from './tools/ConfigProxy';

/*
Permet de fermer les connexions quand l'onglet n'est pas visible
 */
export class DocumentHiddenModelXMercureService extends ModelXMercureService {
	public constructor(entryPoint, token?) {
		super(entryPoint, token);

		document.addEventListener('visibilitychange', () => {
			if (document.hidden) {
				this.closeAll();
			} else {
				this.playAll();
			}
		});
	}

	public listenForFetchModel(model, options: any = {}) {
		super.listenForFetchModel(model, options);

		if (document.hidden) {
			this.closeAll();
		}
	}
}

export default new DocumentHiddenModelXMercureService(`${ConfigProxy.getServiceConfig('mercure', 'api_endpoint')}`);
