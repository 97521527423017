import { lazy } from 'react';

const ExportDashboardScreen = lazy(
	() => import(
		/* webpackChunkName: "expenseDashboardScreen" */
		'./screens/ExportDashboardScreen')
);

export const routes = [
	{
		path: '/exports/:exportId',
		exact: true,
		component: ExportDashboardScreen,
		hideBreadcrumb: true
	},
];
