import BudgetModel  from './BudgetModel';
import { computed } from 'mobx';

export default class CategoryBudgetModel extends BudgetModel {
	static get path(): string {
		return '/project_categories/_budget';
	}

	@computed
	public get activatedExpenseGroups() {
		return this.get('activatedExpenseGroups', []);
	}
}
