import './Layout.scss';

import React, {Suspense} from 'react';
import {inject, observer}     from 'mobx-react';
import {matchPath}            from 'react-router';
import {renderRoutes}         from 'react-router-config';
import {Layout as AntdLayout} from 'antd';

import {JtsProjectMenu, AppName} from 'react-jts-common';

import Trans                 from '../Trans';
import Breadcrumb            from '../Breadcrumb';
import LayoutSider           from './LayoutSider';
import LogoutLink            from '../LogoutLink/LogoutLink';
import lsdSvg                from './../../../icons//lsd.svg';
import AccountMenu           from '../AccountMenu/AccountMenu';
import {getCompanyIdFromUri} from '../../tools/lsdTools';
import ConfigProxy           from '../../tools/ConfigProxy';
import Spin from 'antd/lib/spin';

@inject('appStore')
@observer
class Layout extends React.Component<any> {
	public UNSAFE_componentWillReceiveProps() {
		if (typeof window !== 'undefined') {
			// @ts-ignore
			window.hasPreviousLocation = !!window.previousLocation;
			// @ts-ignore
			window.previousLocation = this.props.location;
		}
	}

	public render() {
		const companyId = getCompanyIdFromUri(window.location.href);

		return <div className="layout">
			<AntdLayout.Header className="app-header">
				<div className="header-left">
					<JtsProjectMenu services={ConfigProxy.get('services')} title={<Trans id="Changer d'application"/>}/>

					<AppName
						title={<Trans id="LSD"/>}
						iconSvg={lsdSvg}
					/>
				</div>
				<div className="header-right">
					<AccountMenu/>
					<div className="logout-link-wrapper">
						<LogoutLink renderAsIcon/>
					</div>
				</div>
			</AntdLayout.Header>
			<AntdLayout className="app-body">
				<LayoutSider location={this.props.location}/>

				<AntdLayout.Content className="app-page-wrapper" key={`layout-content-key-${companyId}`}>
					{this._getBreadcrumbs()}

					<div className="app-page">
						<Suspense fallback={(
							<div className="lsd-suspense">
								<Spin spinning/>
							</div>
						)}>
							{renderRoutes(this.props.route.routes)}
						</Suspense>
					</div>
				</AntdLayout.Content>
			</AntdLayout>
		</div>;
	}

	private _getBreadcrumbs() {
		const currentRoute = this.props.route.routes.find((route) => {
			return matchPath(this.props.location.pathname, route) && route.path !== '/*';
		});

		let breadcrumbs = <Breadcrumb/>;
		if (!!currentRoute) {
			if (currentRoute.customBreadcrumbs) {
				breadcrumbs = currentRoute.customBreadcrumbs;
			} else if (currentRoute.hideBreadcrumb) {
				breadcrumbs = null;
			}
		}

		return breadcrumbs;
	}
}

export default Layout;
