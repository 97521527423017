import * as LRU                 from 'lru-cache';
import { ConnectorResult }      from '@mathquis/modelx';
import { PlatformApiConnector } from '../connectors/PlatformApiConnector';

const cache = new LRU({max: 500, maxAge: 1000 * 60 * 60});

export default (connectorConstructor: new (options) => PlatformApiConnector) => {
	return class extends connectorConstructor {
		public async fetch(model: Model, options: object = {}) {
			const cachedAttributes = cache.get(model.path);

			if (cachedAttributes) {
				console.info('Cache found for => ' + model.path, cachedAttributes);

				return new ConnectorResult(cachedAttributes);
			}

			const connectorResult = await super.fetch(model, options);

			cache.set(model.path, connectorResult.attributes);

			return connectorResult;
		}

		public async list(collection, options) {
			const connectorResults = await super.list(collection, options);
			const tempCacheModel = new collection.model();

			connectorResults.items.forEach(attributes => {
				cache.set(tempCacheModel.set(attributes).path, attributes);
			});

			return connectorResults;
		}
	};
};
