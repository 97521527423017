import axios, { AxiosInstance } from 'axios';
import { PlatformApiConnector } from './PlatformApiConnector';

export class PlatformPublicApiConnector extends PlatformApiConnector {

	protected client: AxiosInstance;

	public initialize() {
		this.client = axios.create({
			...this.options,
			headers: {
				...this.options.headers,
				'Accept': 'application/ld+json',
				'all-locales': 1
			},
			responseType: 'json'
		});
	}
}
