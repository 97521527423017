import FondationsApiModel from '../global/FondationsApiModel';
import {computed} from 'mobx';

export default class EntityModel extends FondationsApiModel {
	static get path(): string {
		return '/entities/{?id}';
	}

	@computed
	get corporateAndCommercialName() {
		let corporateAndCommercialName = '';
		if (!!this.get('corporateName')) {
			corporateAndCommercialName = this.get('corporateName', '') + ' ';
		}
		if (!!this.get('commercialName')) {
			corporateAndCommercialName += `<${this.get('commercialName', '')}>`;
		}
		return corporateAndCommercialName;
	}
}
