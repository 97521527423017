import {observable} from 'mobx';

import {SplitViewCollection} from 'modelx-jts-common';

import AbstractSplitViewStore from './AbstractSplitViewStore';
import ExpenseModel           from '../models/ExpenseModel';
import ExpenseIdModel         from '../models/ExpenseIdModel';
import UserApiCollection      from '../collections/UserApiCollection';

class ExpenseSplitViewStore extends AbstractSplitViewStore {

	@observable
	public expenseCollection = new SplitViewCollection(ExpenseModel, ExpenseIdModel);

	@observable
	public userCollection = new UserApiCollection();

}

export default new ExpenseSplitViewStore();
