import './CompaniesNavigation.scss';

import React                 from 'react';
import {withRouter, NavLink} from 'react-router-dom';
import {inject, observer}    from 'mobx-react';
import {matchPath}           from 'react-router';
import Menu                  from 'antd/lib/menu';

import {IconSvg, JtsPicture} from 'react-jts-common';

import settingsSvg       from '../../../icons//settings.svg';
import Trans             from '../Trans';
import routes            from '../../routes';
import currentSession    from '../../currentSession';
import MenuExpenseNotice from './MenuExpenseNotice';

export interface IMainNavigationProps {
	appStore?: any;
	history;
	isANoSelectedCompanyPage: any;
	location;
	onCompanyChange: (id: string) => void;
	setCurrentCompanyId: (id: string) => void;
}

@inject('appStore')
@observer
class CompaniesNavigation extends React.Component<IMainNavigationProps> {

	private _selectedKeys = [];

	public render() {
		const {appStore, setCurrentCompanyId} = this.props;

		this._selectedKeys = [];
		this._getCurrentSelectedKeys(routes);

		return <div id="main_navigation" className="menu-div">

			<div className="company-navigation">
				{appStore.currentUserCompanyCollection.map(company => this._renderCompanyMenu(company))}
			</div>

			{currentSession.isSuperAdmin && (
				<Menu
					selectedKeys={this._selectedKeys}
					id="navigation_settings"
					mode="vertical"
					theme="dark"
				>
					<Menu.SubMenu key="setting" className="setting-menu" title={
						<IconSvg className="nav-icon" svg={settingsSvg}/>
					}>
						{/*USERS*/}
						<Menu.Item key="/settings/accounts" onClick={() => setCurrentCompanyId(null)}>
							<NavLink to="/settings/accounts">
								<span><Trans id="Utilisateurs"/></span>
							</NavLink>
						</Menu.Item>

						{/*COMPANIES*/}
						<Menu.Item key="/settings/companies" onClick={() => setCurrentCompanyId(null)}>
							<NavLink to="/settings/companies">
								<span><Trans id="Sociétés"/></span>
							</NavLink>
						</Menu.Item>

						{/*CURRENCY LIST*/}
						<Menu.Item key="/settings/currency" onClick={() => setCurrentCompanyId(null)}>
							<NavLink to="/settings/currency">
								<span><Trans id="Devises"/></span>
							</NavLink>
						</Menu.Item>

						{/*CONFIGS*/}
						<Menu.Item key="/settings/configs" onClick={() => setCurrentCompanyId(null)}>
							<NavLink to="/settings/configs">
								<span><Trans id="Configuration"/></span>
							</NavLink>
						</Menu.Item>

					</Menu.SubMenu>
				</Menu>
			)}

		</div>;
	}

	private _renderCompanyMenu(company) {
		const {appStore, isANoSelectedCompanyPage, setCurrentCompanyId} = this.props;
		const isActive = !isANoSelectedCompanyPage
			&& appStore.currentCompanyId && appStore.currentCompanyId + '' === company.id + '';

		const content = <div className="nav-icon-container">
			<MenuExpenseNotice companyId={company.id}/>

			<JtsPicture
				uri={company.get('logo.objectUrl')}
				height={34} width={34}
				previewDisabled
			/>
		</div>;

		const to = `/companies/${company.id}/my-tasks`;

		return <div
			key={`company-navigation-${company.id}`}
			onClick={() => setCurrentCompanyId(company.id)}
			className={isActive ? 'active' : ''}
		>
			<NavLink to={to}>{content}</NavLink>
		</div>;
	}

	private _getCurrentSelectedKeys(navRoutes) {
		for (const route of navRoutes) {
			const matchConfig = {
				path: route.path,
				exact: route.path === '/'
			};

			if (matchPath(this.props.location.pathname, matchConfig) && route.path !== '/*') {
				this._selectedKeys.push(route.path);
			}

			if (route.routes) {
				this._getCurrentSelectedKeys(route.routes);
			}
		}
	}
}

export default withRouter(CompaniesNavigation);
