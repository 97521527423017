import LsdApiModel             from './global/LsdApiModel';
import {FileDownloadConnector} from './connectors/FileDownloadConnector';
import ConfigProxy             from '../tools/ConfigProxy';

export const fileDownloadConnector = new FileDownloadConnector({
	baseURL: ConfigProxy.getServiceConfig('lsd', 'api_endpoint'),
});

export default class ExpensePdfModel extends LsdApiModel {
	static get path(): string {
		return '/expense/{?id}/pdf';
	}

	static get connector() {
		return fileDownloadConnector as any;
	}
}
