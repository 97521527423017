import { Collection, ConnectorResults } from '@mathquis/modelx';
import { PlatformApiConnector }         from './PlatformApiConnector';
import moment                           from 'moment';
import qs                               from 'qs';
import downloadFileFromReadableStream   from '../../modules/utils/downloadFileFromReadableStream';

export class PlatformApiCsvConnector extends PlatformApiConnector {
	public initialize() {
		// DO NOTHING
	}

	// Collection methods
	public list(collection: Collection, options: {
		downloadFileName?: string,
		limit?: number,
		offset?: number,
		filters?: any;
		sorts?: object
	} = {}): any {
		const params: {
			pagination: boolean;
			itemsPerPage: number;
			page: number;
		} = {...options.filters};

		params.pagination = !!(options.limit || options.offset);
		if (params.pagination) {
			if (options.limit) {
				params.itemsPerPage = Math.max(1, options.limit);
			}
			if (options.offset) {
				params.page = Math.floor(options.offset / Math.max(1, params.itemsPerPage)) + 1;
			}
		}

		if (options.sorts) {
			Object.keys(options.sorts).forEach(key => {
				params['order[' + key + ']'] = options.sorts[key] ? 'ASC' : 'DESC';
			});
		}

		return this.request(collection.path, {
			...options,
			method: 'get',
			params
		}).then((response) => {
			const dateString = moment().format('YYYYMMDD_HHmm_');

			downloadFileFromReadableStream(response, dateString + options.downloadFileName + '.csv');

			return new ConnectorResults([], response);
		})
			.catch((err) => console.error(err));
	}

	protected request(path: string, options: any = {}): any {
		return new Promise((resolve, reject) => {
			if (this.currentSession.isExpired) {
				this.currentSession.refresh().then(() => {
						resolve();
					},
					(err) => {
						reject(err);
					});
			} else {
				resolve();
			}
		}).then(
			() => {
				let url: string = this.options.baseURL + path;
				url += url.indexOf('?') >= 0 ? '' : '?';
				url += qs.stringify(options.params || {});
				return fetch(url, {
					...options,
					headers: {
						...options.headers,
						'Accept': 'text/csv',
						'X-Platform-Authorization': 'Bearer ' + this.currentSession.token,
					}
				}).then((response) => {
					if (!response.ok) {
						throw response;
					}
					return response;
				});
			}
		).catch((err) => {
			this.onRequestError(err);
		});
	}
}
