import _get from 'lodash/get';

// Permet de soumettre les formulaires en tapant sur "ctrl + entrée"
document.addEventListener('keypress', (e: any) => {
	if (e.ctrlKey) {
		const key = e.which || e.keyCode;

		if ((key === 13 || key === 10) && e.target.type === 'textarea') {
			if (_get(e, 'target.form.querySelector')) {
				const buttonSubmit = e.target.form.querySelector('button[type="submit"]');

				if (buttonSubmit) {
					buttonSubmit.click();
				}
			}
		}
	}
});
