import {computed} from 'mobx';
import _camelCase from 'lodash/camelCase';

import {ApiCollection} from 'modelx-jts-common';

import PoleBudgetModel        from '../models/PoleBudgetModel';
import CategoryBudgetModel    from '../models/CategoryBudgetModel';
import SubCategoryBudgetModel from '../models/SubCategoryBudgetModel';
import ProjectBudgetModel     from '../models/ProjectBudgetModel';

const typeChildren = {
	ProjectCategory: 'ProjectSubCategory',
	ProjectPole: 'ProjectCategory',
	ProjectSubCategory: 'Project',
};

const typeParents = {
	Project: 'ProjectSubCategory',
	ProjectCategory: 'ProjectPole',
	ProjectSubCategory: 'ProjectCategory',
};

export class OrganigramCollectionStore {
	public projectPoleCollection = new ApiCollection(PoleBudgetModel)
		.setFilter('position', true);

	public projectCategoryCollection = new ApiCollection(CategoryBudgetModel)
		.setFilter('position', true);

	public projectSubCategoryCollection = new ApiCollection(SubCategoryBudgetModel)
		.setFilter('position', true);

	public projectCollection = new ApiCollection(ProjectBudgetModel)
		.setFilter('position', true);

	private _currentExpenseGroupId: number;

	public constructor() {
		this.getTitleForProjectUrl = this.getTitleForProjectUrl.bind(this);
	}

	public async initAsync(expenseGroupId) {

		const promises = [];

		const isNewExpenseGroup = this._currentExpenseGroupId !== expenseGroupId;

		if (isNewExpenseGroup || !this.projectPoleCollection.isLoaded) {
			promises.push(
				this.projectPoleCollection
					.setFilter('activatedExpenseGroups', expenseGroupId)
					.list()
			);
		}

		if (isNewExpenseGroup || !this.projectCategoryCollection.isLoaded) {
			promises.push(
				this.projectCategoryCollection
					.setFilter('activatedExpenseGroups', expenseGroupId)
					.setFilter('projectPole.activatedExpenseGroups', expenseGroupId)
					.list()
			);
		}

		if (isNewExpenseGroup || !this.projectSubCategoryCollection.isLoaded) {
			promises.push(
				this.projectSubCategoryCollection
					.setFilter('activatedExpenseGroups', expenseGroupId)
					.setFilter('projectCategory.activatedExpenseGroups', expenseGroupId)
					.list()
			);
		}

		if (isNewExpenseGroup || !this.projectCollection.isLoaded) {
			promises.push(
				this.projectCollection
					.setFilter('expenseGroup', expenseGroupId)
					.list()
			);
		}

		await Promise.all(promises);

		this._currentExpenseGroupId = expenseGroupId;
	}

	public getTitleForProjectUrl(url) {
		const titleSegments = [];

		const project = this.projectCollection.find(p => p.get('@id') === url);

		if (project) {
			const subCategory = this.projectSubCategoryCollection.find(p => p.id === project.get('projectSubCategory.id'));

			if (subCategory) {
				const category = this.projectCategoryCollection.find(p => p.get('@id') === subCategory.get('projectCategory'));

				if (category) {
					const pole = this.projectPoleCollection.find(p => p.get('@id') === category.get('projectPole'));

					if (pole) {
						titleSegments.push(pole.get('title'));
					}

					titleSegments.push(category.get('title'));
				}

				titleSegments.push(subCategory.get('title'));
			}

			titleSegments.push(project.get('title'));
		}

		return titleSegments.join(' > ');
	}

	public getAllChildren(model: Model) {
		return this._getEntityRecursive([model.id as number], model.get('@type'))
			.filter(m => m.get('@type') !== model.get('@type'));
	}

	public getAllChildrenWithType(model, type: OrganigramEntityType) {
		return this.getAllChildren(model).filter(m => m.get('@type') === type);
	}

	public getModelChildren(m: Model) {
		switch (m.get('@type')) {
			case 'ProjectPole':
				return this.projectCategoryCollection.filter(i => i.get('projectPole.id') === m.id);

			case 'ProjectCategory':
				return this.projectSubCategoryCollection.filter(i => i.get('projectCategory.id') === m.id);

			case 'ProjectSubCategory':
				return this.projectCollection.filter(i => i.get('projectSubCategory.id') === m.id);
		}

		return [];
	}

	public getModelParents(model: Model, res = {}) { // TODO REMOVE
		if (model && model.id) {
			const parentType = typeParents[model.get('@type')];
			const camelCaseType = _camelCase(model.get('@type'));

			res[camelCaseType] = model;

			if (parentType) {
				const collection = this.getTypeCollection(parentType);

				if (collection) {
					const parentModel = collection.find(item => item.get('@id') === model.get(_camelCase(parentType)));

					this.getModelParents(parentModel, res);
				}
			}
		}

		return res;
	}

	public getTypeCollection(type: OrganigramEntityType): Collection {
		switch (type) {
			case 'ProjectPole':
				return this.projectPoleCollection;

			case 'ProjectCategory':
				return this.projectCategoryCollection;

			case 'ProjectSubCategory':
				return this.projectSubCategoryCollection;

			case 'Project':
				return this.projectCollection;
		}

		return null;
	}

	@computed
	public get isLoading() {
		return this.projectPoleCollection.isLoading ||
			this.projectCategoryCollection.isLoading ||
			this.projectSubCategoryCollection.isLoading ||
			this.projectCollection.isLoading;
	}

	private _getEntityRecursive(ids: number[], type: OrganigramEntityType, result = []) {
		const collection = this.getTypeCollection(type);

		if (collection) {
			ids.forEach(id => {
				const model = collection.find(i => i.id === id);

				if (model) {
					result.push(model);

					const childrenType = typeChildren[type];

					if (childrenType) {
						const childrenIds = this.getModelChildren(model).map(i => i.id as number);

						result = result.concat(this._getEntityRecursive(childrenIds, childrenType));
					}
				}
			});
		}

		return result;
	}
}

export default new OrganigramCollectionStore();
