import FondationsApiModel             from './FondationsApiModel';
import ConfigProxy                    from '../../tools/ConfigProxy';
import { PlatformPublicApiConnector } from '../connectors/PlatformPublicApiConnector';

export const publicConnector = new PlatformPublicApiConnector({
	baseURL: ConfigProxy.getServiceConfig('fondations', 'api_endpoint'),
});

export default class FondationsPublicApiModel extends FondationsApiModel {
	static get connector() {
		return publicConnector;
	}
}
