import {ApiCollection} from 'modelx-jts-common';
import AccountLsdModel from '../models/AccountLsdModel';
import AccountModel    from '../models/fondationsModels/AccountModel';

export default class UserApiCollection extends ApiCollection {

	public fondationsUserCollection;

	constructor() {
		super(AccountLsdModel);

		this.fondationsUserCollection = new ApiCollection(AccountModel);
	}

	public list(options?: object): Promise<Collection> {
		return new Promise((resolve, reject) => {
			super.list(options)
				.then(() => {
					resolve(this);

					const fondationsUsersIds = this.map(user => {
						const arr = user.get('userUrn', '').split('.');

						if (arr && arr.length) {
							const id = arr.pop();
							return id;
						}
					});

					this.fondationsUserCollection.setFilter('id', fondationsUsersIds);
					this.fondationsUserCollection
						.list()
						.then(() => {
							const fondationsUsersModels = this.fondationsUserCollection.models.slice();

							this.forEach((user: AccountLsdModel) => {
								user.isLoaded = false;

								const entityIndex = fondationsUsersModels.findIndex(fondationsUser => {
									return fondationsUser.get('@urn') === user.get('userUrn');
								});

								if (entityIndex > -1) {
									user.setUser(fondationsUsersModels[entityIndex]);
								}

								user.isLoaded = true;
							});
						});
				})
				.catch(err => reject(err));
		});
	}
}
