import {Model}               from '@mathquis/modelx';
import {WebStorageConnector} from '@mathquis/modelx-webstorage-connector';
import Connector             from '@mathquis/modelx/lib/types/connector';

const localStorageConnector = new WebStorageConnector({storage: localStorage}) as any;

export default class CompanyCookie extends Model {
	static get connector(): Connector {
		return localStorageConnector;
	}

	static get path(): string {
		return '/users/{id}/company';
	}

	static get defaults(): object {
		return {
			company: null,
		};
	}
}
