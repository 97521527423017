import './LoginForm.scss';

import React      from 'react';
import {observer} from 'mobx-react';
import {Link}     from 'react-router-dom';
import get        from 'lodash/get';
import Form       from 'antd/lib/form';
import Button     from 'antd/lib/button';
import Input      from 'antd/lib/input';
import Card       from 'antd/lib/card';
import Icon       from 'antd/lib/icon';

import currentSession from '../../../../currentSession';
import Trans          from '../../../../components/Trans';
import i18n           from '../../../../components/i18n';

import {JtsFormMessage, MessageType} from 'react-jts-common';

@observer
class LoginForm extends React.Component<{ form }> {

	public state = {
		errors: []
	};

	public render() {
		const {errors} = this.state;
		const FormItem = Form.Item;
		const {getFieldDecorator} = this.props.form;

		return (
			<div className="login-page">
				<Card className="login-form" style={{width: 450}}>

					<div className="welcome-message">
						<Trans id="Bienvenue"/>
					</div>

					<div className="app-title">LSD</div>

					{currentSession.isLoggedIn ? (
						<div className="loginForm__loggedInContainer">
							<div>
								Vous êtes connecté !
							</div>
							<Link to="/">Aller à l'accueil</Link>
						</div>

					) : (
						<Form onSubmit={this.handleSubmit}>
							{errors.length ? <JtsFormMessage message={this.state.errors} type={MessageType.Error}/> : null}
							<FormItem>
								{getFieldDecorator('_username', {
									rules: [{required: true, message: i18n._('Veuillez renseigner votre email')}]
								})(
									<Input
										prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}}/>}
										placeholder={i18n._('Votre adresse mail')} size="large" className="login-input"
										onChange={() => {
											this.setState({errors: []});
										}}
									/>
								)}
							</FormItem>
							<FormItem>
								{getFieldDecorator('_password', {
									rules: [{required: true, message: i18n._('Veuillez renseigner votre mot de passe')}]
								})(
									<Input.Password
										prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
										placeholder={i18n._('Votre mot de passe')}
										size="large"
										className="login-input"
										onChange={() => {
											this.setState({errors: []});
										}}
									/>
								)}
							</FormItem>
							<FormItem>
								<Button
									type="primary"
									size="large"
									htmlType="submit"
									className="login-form-button"
									loading={currentSession.isProcessing}
									disabled={currentSession.isProcessing}
								>
									<Trans id="Connectez-vous"/>
								</Button>
							</FormItem>
							<div className="login-form-forgot">
								<Link to="/forget_password">
									<Trans id="Mot de passe oublié"/>
								</Link>
							</div>
						</Form>
					)}

				</Card>
			</div>
		);
	}

	private handleSubmit = ev => {
		ev.preventDefault();

		this.props.form.validateFields((err, values) => {
			if (!err) {
				currentSession.login(values._username, values._password).catch(error => {
					const status = get(error, 'response.data.code', 0);

					this.setState({
						errors: [
							status === 401 ? i18n._('Combinaison email / mot de passe invalide') : i18n._('Une erreur est survenue')
						]
					});
				});
			}
		});
	}
}

export default Form.create()(LoginForm);
