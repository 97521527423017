import './LogoutLink.scss';

import React          from 'react';
import Tooltip        from 'antd/lib/tooltip';
import Trans          from '../Trans';
import currentSession from '../../currentSession';
import logoutSvg      from '../../../icons//logout.svg';

import {IconSvg} from 'react-jts-common';

export interface ILogoutLinkProps {
	logout?: () => void;
	renderAsIcon: boolean;
}

export default class LogoutLink extends React.Component<ILogoutLinkProps> {
	public render() {
		const {logout, renderAsIcon} = this.props;

		const title = <Trans id="Déconnexion"/>;
		if (renderAsIcon) {
			return <Tooltip title={title} placement="bottomRight" arrowPointAtCenter>
				<div className="logout-icon-wrapper" onClick={() => currentSession.realLogout()}>
					<IconSvg className="logout-icon" svg={logoutSvg} size={16}/>
				</div>
			</Tooltip>;
		}

		return <div onClick={() => logout()} className="logout-link">title</div>;
	}
}
