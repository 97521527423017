import { lazy } from 'react';

const AccountingDocumentDashboardScreen = lazy(
	() => import(
		/* webpackChunkName: "accountingDocumentDashboardScreen" */
		'./screens/AccountingDocumentDashboardScreen')
);

const AccountingDocumentsSplitViewScreen = lazy(() => import(
	/* webpackChunkName: "accountingDocumentsSplitViewScreen" */
	'./screens/AccountingDocumentsSplitViewScreen'
));

const AccountingDocumentsAddScreen = lazy(() => import(
	/* webpackChunkName: "accountingDocumentsAddScreen" */
	'./screens/AccountingDocumentsAddScreen'
));

export const routes = [
	{
		component: AccountingDocumentDashboardScreen,
		exact: true,
		hideBreadcrumb: true,
		path: '/accounting-documents/:accountingDocumentId',
	},
	{
		component: AccountingDocumentsSplitViewScreen,
		exact: true,
		hideBreadcrumb: true,
		path: '/companies/:companyId/accounting-documents/',
	},
	{
		component: AccountingDocumentsAddScreen,
		exact: true,
		hideBreadcrumb: true,
		path: '/companies/:companyId/accounting-documents/add',
	},
];
