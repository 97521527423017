import { lazy } from 'react';

const accountSplitViewScreen = lazy(
	() => import(
		/* webpackChunkName: "accountSplitViewScreen" */
		'./screens/AccountSplitViewScreen')
);

const accountDashboardScreen = lazy(
	() => import(
		/* webpackChunkName: "accountDashboardScreen" */
		'./screens/AccountDashboardScreen')
);

const userGroupCompanyModifyScreen = lazy(
	() => import(
		/* webpackChunkName: "userGroupCompanyModifyScreen" */
		'./screens/UserGroupCompanyModifyScreen')
);

export const routes = [{
	path: '/settings/accounts',
	component: accountSplitViewScreen,
	exact: true,
	hideBreadcrumb: true
}, {
	path: '/settings/accounts/:accountId',
	exact: true,
	component: accountDashboardScreen
}, {
	path: '/settings/accounts/:accountId/user_group_companies/modify',
	exact: true,
	component: userGroupCompanyModifyScreen
}];
