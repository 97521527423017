import {Link, withRouter} from 'react-router-dom';

import React                 from 'react';
import classNames            from 'classnames';
import {inject}              from 'mobx-react';
import {RouteComponentProps} from 'react-router';
import _Breadcrumb           from 'antd/lib/breadcrumb';

import Trans from './Trans';

const breadcrumbNameMap = [
	{
		route: '/companies',
		label: <Trans id="Sociétés"/>,
		noLink: true
	},
	{
		route: '/companies/\\d+',
		label: ({appStore}) => appStore.currentCompany.get('name'),
		noLink: true
	},
	{
		route: '/companies/\\d+/expense_groups/\\d+/default_responsibles',
		label: <Trans id="Responsables par défaut"/>,
		noLink: true
	},
	{
		route: '/companies/\\d+/expense_groups/\\d+/organigram/add_expense',
		label: <Trans id="Ajouter une dépense"/>,
		noLink: true
	},
	{
		route: '/companies/\\d+/accounting-documents',
		label: <Trans id="Pièces comptables"/>,
		noLink: true
	},
	{
		route: '/companies/\\d+/accounting-documents/add',
		label: <Trans id="Ajouter une pièce comptable"/>,
		noLink: true
	},
	{
		route: '/companies/\\d+/my-tasks',
		label: <Trans id="Mes dépenses à traiter"/>,
		noLink: true
	},
	{
		route: '/companies/\\d+/my-vouchers',
		label: <Trans id="Mes paiements à traiter"/>,
		noLink: true
	},
	{
		route: '/companies/\\d+/state',
		label: <Trans id="Tableau de bord société"/>,
		noLink: true
	},
	{
		route: '/companies/\\d+/expense_groups',
		label: <Trans id="Groupe de dépenses"/>,
		noLink: true
	},
	{
		route: '/companies/\\d+/expense_groups/\\d+',
		label: <Trans id="Gestion"/>,
		noLink: true
	},
	{
		route: '/settings',
		label: <Trans id="Paramètres"/>,
		noLink: true
	},
	{
		route: '/settings/configs',
		label: <Trans id="Configuration"/>,
		noLink: false
	},
	{
		route: '/settings/currency',
		label: <Trans id="Devises"/>,
		noLink: false
	},
	{
		route: '/settings/translation_management',
		label: <Trans id="Traductions"/>,
		noLink: false
	},
	{
		route: '/settings/accounts',
		label: <Trans id="Utilisateurs"/>,
		noLink: false
	},
	{
		route: '/settings/accounts/\\d+',
		label: <Trans id="Fiche utilisateur"/>,
		noLink: false
	},
	{
		route: '/settings/accounts/\\d+/user_group_companies',
		label: null,
		noLink: true
	},
	{
		route: '/settings/accounts/\\d+/user_group_companies/\\d+',
		label: null,
		noLink: true
	},
	{
		route: '/settings/accounts/\\d+/user_group_companies/modify',
		label: <Trans id="Modifier les fonctions d'un utilisateur"/>,
		noLink: false
	},
	{
		route: '/settings/companies',
		label: <Trans id="Sociétés"/>,
		noLink: false
	},
	{
		route: '/settings/companies/add',
		label: <Trans id="Ajouter une société"/>,
		noLink: false
	},
	{
		route: '/settings/companies/\\d+',
		label: <Trans id="Fiche société"/>,
		noLink: false
	},
	{
		route: '/settings/companies/\\d+/modify_info',
		label: <Trans id="Modifier les informations de la société"/>,
		noLink: false
	},
	{
		route: '/settings/companies/\\d+/user_group_companies',
		label: null,
		noLink: true
	},
	{
		route: '/settings/companies/\\d+/user_group_companies/\\d+',
		label: null,
		noLink: true
	},
	{
		route: '/settings/companies/\\d+/user_group_companies/modify',
		label: <Trans id="Modifier les fonctions d'une société"/>,
		noLink: false
	},
	{
		route: '/settings/expense_groups',
		label: <Trans id="Groupes de dépenses"/>,
		noLink: false
	},
	{
		route: '/settings/expense_groups/add',
		label: <Trans id="Ajouter un groupe de dépenses"/>,
		noLink: false
	},
	{
		route: '/settings/expense_groups/\\d+',
		label: <Trans id="Fiche groupe de dépenses"/>,
		noLink: false
	},
	{
		route: '/settings/expense_groups/\\d+/modify',
		label: <Trans id="Modifier les informations du groupe de dépenses"/>,
		noLink: false
	},
	{
		route: '/shows',
		label: <Trans id="Festivals"/>,
		noLink: false
	},
	{
		route: '/shows/add',
		label: <Trans id="Ajouter un festival"/>,
		noLink: false
	},
	{
		route: '/shows/\\d+',
		label: <Trans id="Fiche festival"/>,
		noLink: false
	},
	{
		route: '/shows/\\d+/modify',
		label: <Trans id="Modifier le festival"/>,
		noLink: false
	},
	{
		route: '/shows/\\d+/add_entertainment',
		label: <Trans id="Ajouter un événement"/>,
		noLink: false
	},
	{
		route: '/shows/\\d+/modify_entertainment',
		label: <Trans id="Événement"/>,
		noLink: true
	},
	{
		route: '/shows/\\d+/modify_entertainment/\\d+',
		label: <Trans id="Modifier un événement"/>,
		noLink: false
	},
	{
		route: '/project_poles/\\d+',
		label: <Trans id="Pôle de projet"/>,
		noLink: false
	},
	{
		route: '/project_categories/\\d+',
		label: <Trans id="Catégorie de projet"/>,
		noLink: false
	},
	{
		route: '/project_sub_categories/\\d+',
		label: <Trans id="Sous-catégorie de projet"/>,
		noLink: false
	},
	{
		route: '/projects/\\d+',
		label: <Trans id="Projet"/>,
		noLink: false
	},
	{
		route: '/(project_poles|project_categories|project_sub_categories|projects)/\\d+/modify',
		label: <Trans id="Modifier"/>,
		noLink: false
	},
	{
		route: '(project_poles|project_categories|project_sub_categories|projects)',
		label: <Trans id="Organigramme"/>,
		noLink: true
	},
	// Breadcrumb names to be completed with other modules routes
];

@withRouter
@inject('appStore')
export default class Breadcrumb extends React.Component<Partial<RouteComponentProps> & { appStore? }> {

	public render() {
		const {location} = this.props;

		const pathSnippets = location.pathname.split('/').filter(i => i);

		const extraBreadcrumbItems = [];

		for (let i = pathSnippets.length - 1; i >= 0; i--) {
			let breadCrumbItemLabel;
			const url = `/${pathSnippets.slice(0, i + 1).join('/')}`;

			const matched = breadcrumbNameMap.find(nameMap => url.match(`^${nameMap.route}$`)) as any;

			if (!matched) {
				breadCrumbItemLabel = <Trans id="Cette page n'existe pas"/>;
			} else {
				const {label} = matched;

				breadCrumbItemLabel = typeof label === 'function' ? label(this.props as any) : label;
			}

			extraBreadcrumbItems.push(
				<_Breadcrumb.Item key={url}>
					{
						(!matched || matched.noLink) ?
							breadCrumbItemLabel
							:
							<Link to={matched.url || url} className={classNames({active: i === pathSnippets.length - 1})}>
								{breadCrumbItemLabel}
							</Link>
					}
				</_Breadcrumb.Item>
			);
		}

		let breadcrumbItems = location.pathname === '/' ? [
			<_Breadcrumb.Item key="home">
				<Link className={classNames({active: location.pathname === '/'})} to="/">
					<Trans id="Accueil"/>
				</Link>
			</_Breadcrumb.Item>
		] : [];

		breadcrumbItems = breadcrumbItems.concat(extraBreadcrumbItems.reverse());

		return <_Breadcrumb separator="|" className="breadcrumb">
			{breadcrumbItems}
		</_Breadcrumb>;
	}
}
