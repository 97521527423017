import i18n from '../components/i18n';

export const NOT_PAID = {
	label: i18n._('Non Payée'),
	reference: 'NOT_PAID',
};

export const PARTIALLY_PAID = {
	label: i18n._('Payée partiellement'),
	reference: 'PARTIALLY_PAID',
};

export const PAID = {
	label: i18n._('Totalement payée'),
	reference: 'PAID',
};

const references = {NOT_PAID, PARTIALLY_PAID, PAID};

export const getLabel = status => {
	if (!!references[status]) {
		return references[status].label;
	}

	return NOT_PAID.label;
};

export default references;
