import _get      from 'lodash/get';
import AppConfig from '../appConfig.js';
import FrontConfig from '../frontConfig.js';

// @ts-ignore
const appConfig = window.APP_CONFIG || AppConfig || {};
// @ts-ignore
const frontConfig = window.FRONT_CONFIG || FrontConfig || {};

const getServiceConfig = (serviceName, key) => {
	const service = ( get('services') || [] ).find(s => s.id === serviceName );
	if ( !service ) {
		return;
	}
	const value = _get(service, 'configurations.' + key);
	if ( typeof value === 'undefined' ) {
		console.warn('No configuration key "%s" for service "%s"', key, serviceName);
	}

	return value;
};

const getServiceKey = (serviceName, key) => {
	const service = ( get('services') || [] ).find(s => s.id === serviceName );
	if ( !service ) {
		return;
	}
	const value = _get(service, key);
	if ( typeof value === 'undefined' ) {
		console.warn('No configuration key "%s" for service "%s"', key, serviceName);
	}

	return value;
}

const get = key => {
	const config = {...appConfig, ...frontConfig};

	// @ts-ignore
	const value = _get(config, key);

	if (typeof value === 'undefined') {
		console.warn('No env variable defined:', key);
	}

	return value;
};

export default {
	getServiceConfig,
	getServiceKey,
	get,
};
