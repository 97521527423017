import LsdApiModel               from './global/LsdApiModel';
import {computed}                from 'mobx';
import {getLabel, getLabelShort} from '../constants/AccountingDocumentTypes';
import { PAID }                  from '../constants/AccountingDocumentStatuses';
import { PARTIALLY_PAID }        from '../constants/AccountingDocumentStatuses';
import { REGISTERED }            from '../constants/AccountingDocumentStates';

export default class AccountingDocumentBudgetModel extends LsdApiModel {
	static get path(): string {
		return '/accounting_document_budgets/{?id}';
	}

	@computed
	public get accountingDocumentName(): string {
		if (!!this.get('type') && !!this.get('number')) {
			return `${getLabel(this.get('type'))} ${this.get('number')
				? this.get('number')
				: this.get('accountingDocumentMedia.mediaFileName')}`;
		}

		return '';
	}

	@computed
	public get accountingDocumentType(): string {
		return getLabel(this.get('type'));
	}

	@computed
	public get accountingDocumentTypeShort(): string {
		return getLabelShort(this.get('type'));
	}

	@computed
	public get status() {
		return this.get('status');
	}

	@computed
	public get isPaid() {
		return this.status === PAID.reference;
	}

	@computed
	public get isPartiallyPaid() {
		return this.status === PARTIALLY_PAID.reference;
	}

	@computed
	public get closed() {
		return this.get('closed');
	}

	@computed
	public get isRegistered() {
		return this.get('state') === REGISTERED.value;
	}
}
