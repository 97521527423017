import { lazy } from 'react';

const notFoundScreen = lazy(
	() => import(
		/* webpackChunkName: "notFoundScreen" */
		'./screens/NotFoundScreen')
);

const unauthorizedScreen = lazy(
	() => import(
		/* webpackChunkName: "unauthorizedScreen" */
		'./screens/UnauthorizedScreen')
);

export const routes = [
	{
		path: '/errors/404',
		exact: true,
		component: notFoundScreen,
		hideBreadcrumb: true,
	},
	{
		path: '/errors/401',
		exact: true,
		component: unauthorizedScreen,
		hideBreadcrumb: true,
	},
];
