import LsdApiModel  from './global/LsdApiModel';
import { computed } from 'mobx';
import { getLabel } from '../constants/ExpensePaymentVoucherStatus';

export default class ExpensePaymentVoucherModel extends LsdApiModel {
	static get path(): string {
		return '/expense_payment_vouchers/{?id}';
	}

	@computed
	public get amountInCompanyCurrency() {
		return this.get('amountInCompanyCurrency');
	}

	@computed
	public get amountPaidInCompanyCurrency() {
		return this.get('amountPaidInCompanyCurrency');
	}

	@computed
	public get currentResponsible() {
		return this.get('currentResponsible');
	}

	@computed
	public get currentResponsibleId() {
		return this.currentResponsible?.id;
	}

	@computed
	public get expenseId() {
		return this.get('expense.id');
	}

	@computed
	public get position() {
		return this.get('position');
	}

	@computed
	public get status() {
		return this.get('status');
	}

	@computed
	public get statusLabel() {
		return getLabel(this.status);
	}
}
