import { Model }                   from '@mathquis/modelx';
import ConfigProxy                 from '../../tools/ConfigProxy';
import { PlatformApiCsvConnector } from '../connectors/PlatformApiCsvConnector';

export const csvConnector = new PlatformApiCsvConnector({
	baseURL: ConfigProxy.getServiceConfig('fondations', 'api_endpoint'),
});

export default class FondationsApiCsvModel extends Model {
	static get connector() {
		return csvConnector;
	}
}
