import {Model}               from '@mathquis/modelx';
import {WebStorageConnector} from '@mathquis/modelx-webstorage-connector';
import Connector             from '@mathquis/modelx/lib/types/connector';

const localStorageConnector = new WebStorageConnector({storage: localStorage}) as any;

class Menu extends Model {
	static get connector(): Connector {
		return localStorageConnector;
	}

	static get path(): string {
		return '/menu';
	}

	public initialize() {
		this
			.fetch()
			.catch(() => {
				// just catch error
			})
		;
	}

	public toggleState() {
		return this.set({isOpen: !this.get('isOpen')}).save();
	}
}

export default new Menu();
