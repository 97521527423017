import { Model, ConnectorResult }      from '@mathquis/modelx';
import ConfigProxy                     from '../tools/ConfigProxy';
import { PlatformFileUploadConnector } from './connectors/PlatformFileUploadConnector';

export const expenseMediaUploadConnector = new PlatformFileUploadConnector({
	baseURL: ConfigProxy.getServiceConfig('lsd', 'api_endpoint'),
});

export default class ExpenseMediaUploadModel extends Model {
	static get path(): string {
		return '/expense_media';
	}

	static get connector() {
		return expenseMediaUploadConnector as any;
	}

	// @ts-ignore
	public save(options: object = {}) {
		return new Promise((resolve, reject) => {
			const formData = new FormData();

			const attributes = this.attributes;
			Object.keys(attributes).forEach(key => {
				formData.set(key, attributes[key]);
			});

			return this.connector.save(this, {...options, patchAttributes: formData}).then(
				(result: ConnectorResult) => {
					this.onSaveSuccess(result, options);
					resolve(this);
				}
			).catch((err) => reject(err));
		});

	}
}
