import i18n from '../components/i18n';

export const PAID = {
	reference: 'PAID',
	label: i18n._('Payé'),
	color: '#2ecc71',
};

export const PENDING = {
	reference: 'PENDING',
	label: i18n._('En attente de validation'),
	color: '#f39c12',
};

export const PENDING_ADDITIONAL_INFORMATION = {
	reference: 'PENDING_ADDITIONAL_INFORMATION',
	label: i18n._('En attente d\'informations complémentaires'),
	color: '#3777BC',
};

export const REFUSED = {
	reference: 'REFUSED',
	label: i18n._('Refusé'),
	color: '#e74c3c',

};

export const WAITING_FOR_PAYMENT = {
	reference: 'WAITING_FOR_PAYMENT',
	label: i18n._('En attente de paiement'),
	color: '#e67e22',
};

export const getLabel = (reference => {
	if (!!status[reference]) {
		return status[reference].label;
	}
	return reference;
});

const status = {
	PAID,
	PENDING,
	PENDING_ADDITIONAL_INFORMATION,
	REFUSED,
	WAITING_FOR_PAYMENT,
};

export const allStatus = [
	PAID,
	PENDING,
	PENDING_ADDITIONAL_INFORMATION,
	REFUSED,
	WAITING_FOR_PAYMENT,
];

export default status;
